import Image from 'next/image'

const EmptyPlate = () => {
  return (
    <div className="flex flex-row xl:gap-[10px] lg:gap-[10px] md:gap-[10px] sm:gap-[6px] gap-[6px] mb-[20px]">
      <div className="flex flex-row w-auto">
        <Image
          src={'/img/cart/empty_plate.png'}
          priority={true}
          width={96}
          height={96}
          className="object-cover xl:h-[96px] lg:h-[96px] md:h-[86px] h-[74px] xl:w-[96px] lg:w-[96px] md:w-[86px] w-[74px]"
        />
      </div>
      <div className="flex flex-col w-full justify-between mt-[6px] mb-[6px] xl:gap-[10px] lg:gap-[10px] md:gap-[10px] sm:gap-[6px] gap-[6px] font-ringside-ssm-medium lg:text-[16px] lg:leading-[16px] md:text-[16px] md:leading-[16px] text-[13px] leading-[13px]">
        <div className="flex flex-row justify-between gap-[4px] bottom-0 w-full">
          <div className="font-[700] capitalize relative top-1 ">
            An Empty Plate
          </div>
          <button className="hover:cursor-pointer border-none ">
            <Image
              draggable={false}
              height={'20px'}
              width={'20px'}
              src="/img/rebrand/cart_delete.svg"
              alt="delete item svg"
            />
          </button>
        </div>
        <div className="flex flex-row justify-end gap-[4px] bottom-0 w-full">
          <span className="font-[700] xl:text-[20px] xl:leading-[20px] lg:text-[16px] lg:leading-[16px] md:text-[16px] md:leading-[16px] text-[13px] leading-[13px] ">
            $0
          </span>
        </div>
      </div>
    </div>
  )
}

export default EmptyPlate
