export const HOLIDAY_HELPER_SKU = 'HOLHELP23'

/**
 * To access the photo_index, it is the product ID
 * then the variant label.
 *
 * @example
 * photo_index = VARIANT_PHOTO_INDEXS[product_id][variant_label]
 */
export const VARIANT_PHOTO_INDEXS = {
  115: {
    Marinated: 0,
    Plain: 7,
  },
  254: {
    Regular: 0,
    Large: 1,
  },
}

export const PRODUCT_VIDEO_LINKS = {
  'butchers-bundle': '2BgYZKGMYCU',
  'family-feast': 'G1jG62xF0Bo',
  prepper: 'MurseUr4A0g',
  'ranchers-classic': 'rf_t2ALhKBY',
  cattleman: 'hkRtgfCIAqE',
  cowboy: 'hEn64dMs4yk',
  'wild-caught-seafood': 'EnsRwytTPZA',
  'burger-box': 'MS5sDny3PLA',
  'prime-pork': 'EpQpBEI7qvc',
  'whole-chicken': 'dz5vm1RnNCE',
  'better-than-organic-chicken': 'JSTeCjBKxrE',
}
