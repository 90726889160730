import api from '@services/api'
import * as _ from 'lodash'
import { Category } from 'types/categories'
import {
  Product,
  ProductImage,
  ProductVariantOptions,
  OptionSelection,
} from 'types/products'
import { HOLIDAY_HELPER_SKU } from 'constants/products'
import { ReactImageGalleryItem } from 'react-image-gallery'
import { VARIANT_PHOTO_INDEXS } from 'constants/products'
import { contents } from '@utils/ProductData'
import { PRODUCT_VIDEO_LINKS } from 'constants/products'

export const getActiveGiftsList = async (): Promise<Product[]> => {
  const { data: productsRes } = await api.getProducts()
  const { data: products = [] }: { data: Product[] } = productsRes
  const { data: categoriesRes } = await api.getCategories()
  const { data: categories = [] }: { data: Category[] } = categoriesRes

  const giftBoxesCategory = categories.find(
    (category) => category?.name?.toLowerCase?.() === 'gift boxes'
  )

  const filteredProducts = products.filter((product) =>
    product?.categories?.includes?.(giftBoxesCategory?.id)
  )

  return filteredProducts
}

export const getActiveMerchList = async (): Promise<Product[]> => {
  const { data: productsRes } = await api.getProducts()
  const { data: products = [] }: { data: Product[] } = productsRes
  const { data: categoriesRes } = await api.getCategories()
  const { data: categories = [] }: { data: Category[] } = categoriesRes

  const merchCategory = categories.find(
    (category) => category?.name?.toLowerCase?.() === 'merchandise'
  )

  const filteredProducts = products.filter(
    (product) =>
      product?.categories?.includes?.(merchCategory?.id) && product?.is_visible
  )

  return filteredProducts
}

export const getActiveBoxList = async (): Promise<Product[]> => {
  const { data: productsRes } = await api.getProducts()
  const { data: products = [] }: { data: Product[] } = productsRes
  const { data: categoriesRes } = await api.getCategories()
  const { data: categories = [] }: { data: Category[] } = categoriesRes

  const boxesCategory = categories.find(
    (category) => category?.name?.toLowerCase?.() === 'boxes'
  )
  const noPromoBoxesCategory = categories.find(
    (category) => category?.name?.toLowerCase?.() === 'no promo boxes'
  )
  const filteredProducts = products.filter(
    (product) =>
      (product?.categories?.includes?.(boxesCategory?.id) ||
        product?.categories?.includes?.(noPromoBoxesCategory?.id)) &&
      product?.is_visible
  )

  return filteredProducts
}

export const getAddOnItemList = async (): Promise<Product[]> => {
  const { data: productsRes } = await api.getProducts()
  const { data: products = [] }: { data: Product[] } = productsRes
  const { data: categoriesRes } = await api.getCategories()
  const { data: categories = [] }: { data: Category[] } = categoriesRes

  const addOnsCategory = categories.find(
    (category) => category?.name?.toLowerCase() === 'add-ons'
  )
  const filteredProducts = products.filter(
    (product) =>
      product?.categories?.includes?.(addOnsCategory?.id) && product?.is_visible
  )

  return filteredProducts
}

export const getProductModifiers = (product: Product, name: string) => {
  const modifiers = product?.modifiers || []

  const modifier = modifiers?.filter((obj) => {
    return obj?.display_name === name
  })?.[0]

  return modifier || null
}

export const getSubscriptionPrice = (
  product: Product,
  optionValueId?: number,
  selectedVariant?: ProductVariantOptions | null
) => {
  let price = product?.price
  if (
    !_.isNull(selectedVariant) &&
    !_.isUndefined(selectedVariant) &&
    !_.isEmpty(selectedVariant) &&
    selectedVariant?.price !== null
  ) {
    price = selectedVariant?.price
  }
  const subscriptions = getProductModifiers(product, 'Sub Options')
  if (!subscriptions) return null
  let optionValue = subscriptions?.option_values?.find?.((optionValue) =>
    optionValueId ? optionValue?.id === optionValueId : optionValue?.is_default
  )
  if (!optionValue) {
    optionValue = subscriptions?.option_values?.[1]
  }
  const adjusterValue = optionValue?.adjusters?.price?.adjuster_value
  if (!adjusterValue) return price
  if (!adjusterValue || !price) return null
  return price + adjusterValue
}

export const sortProducts = (products: Product[] = []) => {
  return products.sort((a, b) => a.sort_order - b.sort_order)
}

export const getDollarSavings = (product: Product, optionValueId: number) => {
  const subscriptions = getProductModifiers(product, 'Sub Options')
  const hasSubscription = !!subscriptions
  const productPrice =
    hasSubscription && optionValueId
      ? getSubscriptionPrice(product, optionValueId)
      : product.price
  const savings = Number(product.price) - Number(productPrice)
  return savings === Math.floor(savings) ? savings : savings.toFixed(2)
}

export const getPercentageSavings = (
  product: Product,
  optionValueId: number
) => {
  const originalPrice = product?.price
  const subscriptions = getProductModifiers(product, 'Sub Options')
  const hasSubscription = !!subscriptions
  const productPrice =
    hasSubscription && optionValueId
      ? getSubscriptionPrice(product, optionValueId)
      : product.price
  return Math.round(
    ((Number(productPrice) / Number(originalPrice)) * 100 - 100) * -1
  )
}

export const slugifyName = (name: string) =>
  name?.toLowerCase().split(' ').join('-')

/**
 * For a given product or gift, this function checks if the product
 * is the holiday ham, and if so returns the product link.
 *
 * This functionality is required due to the holiday ham being a
 * "product" but temporarily being in the gifts section.
 *
 * @param {Product} [product]
 *
 * @returns {string} link to the gift or product detail page
 */
export const getMoreGiftProductLink = (product: Product): string => {
  const base = product.sku === HOLIDAY_HELPER_SKU ? '/product/' : '/gifts/'

  return `${base}${slugifyName(product.name)}`
}

/**
 *  Returns the thumbnail image from a list of product images.
 *
 * @param {ProductImage[]} [images]
 *
 * @returns {ReactImageGalleryItem}
 */
export const getThumbnailImage = (
  images: ProductImage[]
): ReactImageGalleryItem => {
  if (images?.length < 1) {
    console.error('getThumbnailImage - no images in product object')
    return
  }

  const thumbnail = images?.find((image) => image?.is_thumbnail)
  const thumbnailImage: ReactImageGalleryItem = {
    original: thumbnail?.url_zoom,
    thumbnail: thumbnail?.url_thumbnail,
    description: thumbnail?.description,
  }
  return thumbnailImage
}

/**
 * Sorts a list of images.
 *
 * @param {ProductImage[]} [images]
 *
 * @returns {ProductImage[]}
 */
const sortImages = (images: ProductImage[]) => {
  return images.sort((a, b) => a.sort_order - b.sort_order)
}

/**
 * Sorts and converts a list of ProductImages.
 *
 * @param {ProductImage[]} [images]
 *
 * @returns {ReactImageGalleryItem[]}
 */
export const convertImages = (
  images: ProductImage[]
): ReactImageGalleryItem[] => {
  if (!Array.isArray(images) || images?.length < 1) {
    return []
  }
  const sortedImages = sortImages(images)
  const productImages = sortedImages?.filter((image) => !image?.is_thumbnail)
  const allImages: ReactImageGalleryItem[] = productImages?.map((image) => {
    return {
      original: image?.url_zoom,
      thumbnail: image?.url_standard,
      description: image?.description,
    }
  })
  return allImages
}

/**
 * Takes in a reference to a scrollable, container component
 * and a reference to a component encompassed by that container.
 * It then scrolls in the container so that the sub-component is
 * fully visible.
 *
 * @param {HTMLElement} [container]
 * @param {HTMLElement} [thumbnail]
 *
 * @returns {void}
 */
export const scrollToActiveThumbnail = (
  container: HTMLElement,
  thumbnail: HTMLElement
): void => {
  if (container && thumbnail) {
    const containerRect = container.getBoundingClientRect()
    const thumbnailRect = thumbnail.getBoundingClientRect()

    if (thumbnailRect.left < containerRect.left) {
      container.scrollBy({
        left: thumbnailRect.left - containerRect.left,
        behavior: 'smooth',
      })
    } else if (thumbnailRect.right > containerRect.right) {
      container.scrollBy({
        left: thumbnailRect.right - containerRect.right,
        behavior: 'smooth',
      })
    }
  }
}

/**
 * Accepts a product and returns its variants options if present.
 *
 * @param {Product} [product]
 *
 * @returns {ProductVariantOptions[]}
 */
export const getVariants = (product: Product): ProductVariantOptions[] => {
  let variants: ProductVariantOptions[] = []
  for (let i = 0; i < product.variants.length; i++) {
    const variant_label = product.variants[i]?.option_values[0]?.label
    const variant = {
      ...product.variants[i].option_values[0],
      price: product.variants[i].price,
      photo_index: VARIANT_PHOTO_INDEXS[product.id][variant_label],
    }
    variants.push(variant)
  }
  return variants
}

/**
 * Transforms a ProductVariant object for adding to a cart
 *
 * @param {ProductVariantOptions} [variant]
 *
 * @returns {OptionSelection}
 */
export const transformVariant = (
  variant: ProductVariantOptions
): OptionSelection => {
  const { label, id: option_value, option_id } = variant

  const name = label.toLowerCase()

  return {
    name,
    option_value,
    option_id,
  }
}

/**
 * Gets the title of the Variants selection Component
 *
 * @param {ProductVariantOptions} [variant]
 *
 * @returns {OptionSelection}
 */
export const getVariantOptionsTitle = (product: Product): string => {
  if (product.name === 'Better than Organic Chicken') {
    return 'Preparation'
  }
  return 'Size'
}

/**
 * Gets the display content for a given product and/or variant
 *
 * @param {Propduct} [product]
 * @param {ProductVariantOptions} [selectedVariant]
 *
 * @returns {string[]}
 */
export const getProductDisplayContent = (
  product: Product,
  selectedVariant?: ProductVariantOptions | null
): string[] => {
  const btocPlainLabel = 'Plain'
  const large = 'Large'

  if (selectedVariant?.label === btocPlainLabel) {
    return contents[product.id.toString()]?.plainContents
  } else if (selectedVariant?.label === large) {
    return contents[product.id.toString()]?.largeContents
  }
  return contents[product.id.toString()]?.contents
}

/**
 * Extracts the Product name from the image description
 *
 * @param {string} [description]
 *
 * @returns {string}
 */
const extractProductName = (description: string): string => {
  const prefix = 'Product review video of '
  const index = description.lastIndexOf(prefix)
  if (index === -1) {
    return ''
  }
  return description
    .substring(index + prefix.length)
    .toLowerCase()
    .replace(/ /g, '-')
}

/**
 * Gets the video link from the product description
 *
 * @param {string | undefined} [description]
 *
 * @returns {string}
 */
export const getVideoLink = (description: string | undefined): string => {
  if (!_.isUndefined(description) && !_.isEmpty(description)) {
    if (description.includes('Product review video ')) {
      const product_name = extractProductName(description)
      return PRODUCT_VIDEO_LINKS[product_name]
    }
  }
  return ''
}
