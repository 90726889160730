export const chickenCoupons = ['lucnxdw1k8', 'candy']

export const wingCoupons = [
  'freewings',
  'alex',
  'alisa',
  'allie',
  'beck',
  'ben',
  'shapiro',
  'booster',
  'brett',
  'charlie',
  'clark',
  'cooper',
  'dndpod',
  'dcdraino',
  'exfiles',
  'familymade',
  'glen',
  'glenn',
  'kirk',
  'knowles',
  'matt',
  'michael',
  'nick',
  'paige',
  'publicsq',
  'voetberg',
  'walsh',
  'xfiles',
  'ross',
  'healthy',
  'bottomline',
  'gentleman',
  'gentlemen',
  'whitelaw',
  'katie',
  'patrick',
  'danielle',
  'justin',
  'dailywire',
  'wire',
  'fearless',
  'kirk',
]
