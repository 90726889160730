import Link from 'next/link'
import { GiftCertificateCartItemType } from 'types/carts'
import { formatMoney } from 'utils'

const CartItem = ({
  item,
  onDelete,
}: {
  item: GiftCertificateCartItemType
  onDelete: (item: GiftCertificateCartItemType) => void
}) => {
  const { name, quantity, list_price, image_url } = item

  return (
    <div className="line-item flex flex-row lg:gap-[10px] md:gap-[10px] sm:gap-[6px] gap-[6px] mb-[20px]">
      <div className="flex flex-row w-auto">
        <div className="flex lg:h-[96px] md:h-[96px] sm:h-[64px] h-[64px] lg:w-[96px] md:w-[96px] sm:w-[64px] w-[64px] overflow-hidden">
          <img src={image_url} className="object-cover max-w-full" />
        </div>
      </div>
      <div className="flex flex-col w-full justify-end mt-[6px] mb-[6px] lg:gap-[10px] md:gap-[10px] sm:gap-[6px] gap-[6px] font-ringside-ssm-medium lg:text-[16px] lg:leading-[16px] md:text-[16px] md:leading-[16px] text-[13px] leading-[13px]">
        <div className="flex flex-row justify-between gap-[4px] w-full">
          <div className="font-[700]">{name}</div>
          <button
            onClick={() => onDelete(item)}
            className="hover:cursor-pointer border-none"
          >
            <img
              draggable={false}
              src="/img/rebrand/cart_delete.svg"
              alt="delete item svg"
            />
          </button>
        </div>

        <div className="flex flex-row gap-[4px] justify-between w-full items-end">
          <div className="group inline-block relative lg:w-[24px] md:w-[24px] sm:w-[20px] w-[20px] hover:cursor-pointer">
            <div className="flex flex-col items-center justify-end">
              <span className="font-[400] lg:text-[10px] lg:leading-[10px] md:text-[10px] md:leading-[10px] sm:text-[8px] sm:leading-[8px] text-[8px] leading-[8px]">
                QTY
              </span>
              <div className="flex flex-row lg:h-[24px] md:h-[24px] sm:h-[20px] h-[20px] lg:w-[24px] md:w-[24px] sm:w-[20px] w-[20px] items-center justify-center text-center">
                <span className="font-[400] lg:text-[13px] lg:leading-[13px] md:text-[13px] md:leading-[13px] sm:text-[10px] sm:leading-[10px] text-[10px] leading-[10px]">
                  {quantity}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-end justify-end">
            <div className="flex flex-row lg:h-[24px] md:h-[24px] sm:h-[20px] h-[20px] w-auto items-center justify-center text-center">
              <span className="font-[700]  lg:text-[13px] lg:leading-[13px] md:text-[13px] md:leading-[13px] sm:text-[10px] sm:leading-[10px] text-[10px] leading-[10px]">
                {`$${formatMoney(quantity * list_price)}`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartItem
