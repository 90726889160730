import React, { useEffect, useState, Dispatch, SetStateAction } from 'react'
import CouponStatusComponent from './CouponStatus'
import CouponInput from './CouponInput/CouponInput'
import { CartType } from 'types/carts'
import { wingCoupons, chickenCoupons } from '../GWPCouponList'
import { removeCoupon } from '@utils/coupon'
import Cookies from 'js-cookie'
import { applyCoupon, CouponStatus } from '@utils/coupon'
import { COUPON_CODE } from 'constants/cookies'
import _ from 'lodash'

const Coupon = ({
  cart,
  refetchCart,
  updateGWP,
  gwpProductID,
  setGwpProductID,
}: {
  cart: CartType
  refetchCart: () => Promise<void>
  updateGWP: Function
  gwpProductID: number
  setGwpProductID: Dispatch<SetStateAction<number>>
}) => {
  const [storedCoupon, setStoredCoupon] = useState<string>(
    Cookies.get(COUPON_CODE) || null
  )
  const [discountAmount, setDiscountAmount] = useState<number>(0)
  const [storedCouponActive, setActive] = useState<boolean>(false)
  const [activeCoupon, setActiveCoupon] = useState<string>(
    cart?.coupons[0]?.code || null
  )

  const findGWP = (productId: number) => {
    let numGWPs = 0
    cart?.line_items?.physical_items?.map((item) => {
      if (item?.product_id === productId) {
        numGWPs += item?.quantity
      }
    })
    return numGWPs
  }

  const getSubsInCart = () => {
    let numSubs = 0
    cart?.line_items?.physical_items?.map((item) => {
      const subscription = item.options?.find(
        (option) => option.name === 'Sub Options'
      )?.value

      if (
        !_.isEmpty(subscription) &&
        !_.isUndefined(subscription) &&
        !_.isNull(subscription)
      ) {
        if (subscription.toLowerCase() !== 'one-time purchase') {
          numSubs += item.quantity
        }
      }
    })
    return numSubs
  }

  const handleApplyCode = async (code: string) => {
    if (!cart) {
      setActiveCoupon(null)
      setActive(false)
      return
    }

    const subs = getSubsInCart()

    if (
      (!subs && wingCoupons.includes(code)) ||
      (!subs && chickenCoupons.includes(code))
    ) {
      if (storedCoupon === code) {
        setActive(false)
        setActiveCoupon(null)
      }
      return
    }

    const newStatus = await applyCoupon(code)

    if (newStatus === CouponStatus.VALID) {
      setActive(true)
      setActiveCoupon(code)
      if (code !== storedCoupon) {
        setStoredCoupon(code)
      }
      refetchCart()
    } else {
      if (newStatus === CouponStatus['DOES NOT APPLY']) {
        if (code !== storedCoupon) {
          setStoredCoupon(code)
        }
      }
      setActiveCoupon(null)
      setActive(false)
    }
  }

  const handleGWPUpdate = async (newQuantity: number) => {
    const numGWPs = findGWP(gwpProductID)
    if (newQuantity !== numGWPs) {
      updateGWP({ numSubs: newQuantity, gwpProductId: gwpProductID })
    }
    return newQuantity > 0
  }

  useEffect(() => {
    if (activeCoupon) {
      if (chickenCoupons.includes(activeCoupon.toLowerCase())) {
        setGwpProductID(131)
      } else if (wingCoupons.includes(activeCoupon.toLowerCase())) {
        setGwpProductID(261)
      }
    }
  }, [activeCoupon])

  useEffect(() => {
    const codeFromCookies = Cookies.get(COUPON_CODE)
    const codeToTry = storedCoupon || codeFromCookies

    if (!storedCoupon) {
      setStoredCoupon(codeFromCookies || null)
    }

    if (_.isUndefined(cart) || _.isNull(cart) || _.isEmpty(cart)) {
      setActiveCoupon(null)
      setActive(false)
      Cookies.remove(COUPON_CODE)
      return
    }

    const numGWPs = findGWP(gwpProductID)

    if (!_.isEmpty(cart?.coupons)) {
      const _coupon = cart?.coupons[0]

      if (_coupon) {
        if (
          wingCoupons.includes(_coupon?.code.toLowerCase()) ||
          chickenCoupons.includes(_coupon?.code.toLowerCase())
        ) {
          const subsInCart = getSubsInCart()

          if (numGWPs !== subsInCart) {
            handleGWPUpdate(subsInCart)
          }

          if (subsInCart === 0) {
            removeCoupon(_coupon?.code)
            setActiveCoupon(null)
            setActive(false)
          } else {
            setActiveCoupon(_coupon?.code)
            setActive(true)
            setDiscountAmount(0)
          }
        } else {
          if (_coupon?.code !== storedCoupon) {
            setStoredCoupon(_coupon?.code)
          }
          setActiveCoupon(_coupon?.code)
          setDiscountAmount(_coupon?.discounted_amount)

          if (numGWPs) {
            handleGWPUpdate(0)
          }
        }
      } else {
        if (numGWPs) {
          handleGWPUpdate(0)
        }
        setActiveCoupon(null)
        setActive(false)
      }
    } else {
      if (cart) {
        if (numGWPs) {
          handleGWPUpdate(0)
        }
        if (codeToTry) {
          handleApplyCode(codeToTry)
        }
      } else {
        setStoredCoupon(null)
        setActiveCoupon(null)
        setActive(false)
      }
    }
  }, [cart])

  return (
    <div className="flex flex-col">
      <CouponInput
        cart={cart}
        refetchCart={refetchCart}
        active={storedCouponActive}
        setActive={setActive}
        setStoredCoupon={setStoredCoupon}
        initialCode={storedCoupon}
        activeCoupon={activeCoupon}
        getSubsInCart={getSubsInCart}
        setCurrentGwpProductId={setGwpProductID}
      />
      <div className="font-ringside-ssm-book text-2xs mb-2">
        {activeCoupon &&
          cart?.coupons.some(
            (obj) => obj?.code?.toLowerCase() === activeCoupon?.toLowerCase()
          ) && (
            <CouponStatusComponent
              code={activeCoupon}
              amount={discountAmount}
            />
          )}
      </div>
    </div>
  )
}

export default Coupon
