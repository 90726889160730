import { useState, useEffect } from 'react'

const useWindowLocation = () => {
  const [windowLocation, setWindowLocation] = useState('')

  useEffect(() => {
    const cb = (mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (['childList', 'subtree'].includes(mutation.type)) {
          setWindowLocation(document.location.pathname)
        }
      }
    }

    const observer = new MutationObserver(cb)

    observer.observe(document.body, {
      attributes: false,
      childList: true,
      subtree: true,
    })

    return () => observer.disconnect()
  }, [])

  return windowLocation
}

export default useWindowLocation
