import StickerStar from '@components/icons/StickerStar'

const MerchSticker = ({
  stickerColor,
  stickerText,
  stickerTextColor,
}: {
  stickerText: string
  stickerColor: string
  stickerTextColor: string
}) => {
  return (
    <div className="absolute right-[-14px] bottom-[50px] md:bottom-[60px] lg:bottom-[70px] flex items-center text-center justify-center">
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-[40px] h-[40px]"
      >
        <path
          d="M32 0L38.6258 7.2723L48 4.28719L50.1019 13.8981L59.7128 16L56.7277 25.3742L64 32L56.7277 38.6258L59.7128 48L50.1019 50.1019L48 59.7128L38.6258 56.7277L32 64L25.3742 56.7277L16 59.7128L13.8981 50.1019L4.28719 48L7.2723 38.6258L0 32L7.2723 25.3742L4.28719 16L13.8981 13.8981L16 4.28719L25.3742 7.2723L32 0Z"
          fill={stickerColor}
        />
      </svg>
      <p
        className={`absolute text-[12px] leading-none text-transform: uppercase -rotate-12 font-bold font-ringside-compressed-ssm-bold text-${stickerTextColor}`}
      >
        {stickerText}
      </p>
    </div>
  )
}

export default MerchSticker
