const ShoppingCart = ({ borderColor, fillColor }) => {
  const borderColorStyle = {
    fill: borderColor,
  }
  const fillColorStyle = {
    fill: fillColor,
  }
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width="27"
      height="27"
    >
      <path
        style={borderColorStyle}
        d="m19.72,2.5c-.2-.35-.49-.52-.87-.52H4.12l-.92-1.98H.27v1.5h1.95l2.15,4.55,1.65,3.5-.24.45-1.36,2.5c-.31.58-.32,1.11-.01,1.57.31.47.81.7,1.51.7h12.05v-1.5H5.7l1.4-2.59h7.57c.35,0,.68-.1.97-.29.16-.11.31-.24.44-.39.1-.11.19-.24.27-.38l2.14-3.85,1.23-2.22c.2-.35.2-.7,0-1.05Zm-4.9,6.68h-7.2l-2.75-5.71h13.08l-3.13,5.71Zm2.63,7.72c-.35-.35-.78-.52-1.28-.52s-.92.17-1.27.52c-.35.35-.53.77-.53,1.27s.18.93.53,1.28.77.53,1.27.53.93-.18,1.28-.53c.35-.35.52-.78.52-1.28s-.17-.92-.52-1.27Zm-10,0c-.35-.35-.78-.52-1.28-.52s-.92.17-1.27.52c-.35.35-.53.77-.53,1.27s.18.93.53,1.28.77.53,1.27.53.93-.18,1.28-.53c.35-.35.52-.78.52-1.28s-.17-.92-.52-1.27Z"
      />
      <polygon
        style={borderColorStyle}
        points="17.95 3.47 14.82 9.18 7.62 9.18 4.87 3.47 17.95 3.47"
      />
      <path
        style={fillColorStyle}
        d="m14.82,9.18h-7.2l-2.75-5.71h13.08l-3.13,5.71Z"
      />
    </svg>
  )
}

export default ShoppingCart
