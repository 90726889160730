import { CartType, CartItemType } from 'types/carts'

export default function getAllItems(cart: CartType) {
  if (!cart || !!!cart?.line_items) return []
  return Object.keys(cart?.line_items)?.reduce(
    (acc: CartItemType[], itemListType: string) =>
      acc.concat(cart?.line_items[itemListType]),
    []
  )
}
