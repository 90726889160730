import { useEffect, useContext } from 'react'
import { useRouter } from 'next/router'
import { UserContext } from 'contexts/user-context'
import { noop } from 'utils'

const DEBUG = process.env.NODE_ENV === 'development'
const FACEBOOK_ID = process.env.NEXT_PUBLIC_FACEBOOK_ID
const TABOOLA_ID = process.env.NEXT_PUBLIC_TABOOLA_ID
const KLAVIYO_ID = process.env.NEXT_PUBLIC_KLAVIYO_ID

const log = DEBUG ? (...rest) => console.info(...rest) : noop
let dnt = false
const proxy: (
  provider: string
) => { [key: string]: (...any: any[]) => void } = (provider) =>
  new Proxy(
    {},
    {
      get: (_, key) => (...any: any) => {
        log(
          `${provider}.${String(key)} not called because ${
            dnt ? 'DNT is set' : 'not initialized'
          }`
        )
        log(...any)
      },
    }
  )

const create = () => {
  const state = {
    facebook: {
      init: false,
      pixel: proxy('facebook'),
      lastPath: '',
      pageView: noop,
      track: noop,
    },
    taboola: {
      init: false,
      pixel: proxy('taboola'),
      lastPath: '',
      pageView: noop,
      track: noop,
    },
    klaviyo: {
      init: false,
      pixel: proxy('klaviyo'),
      lastPath: '',
      identified: false,
      pageView: noop,
      track: noop,
    },
  }
  // facebook
  const fbOptions = {
    autoConfig: false,
    debug: DEBUG,
  }

  const useFacebook = () => {
    const router = useRouter()

    useEffect(() => {
      ;(async () => {
        try {
          dnt = navigator?.doNotTrack === '1'
          if (typeof window !== 'undefined' && !state.facebook.init) {
            if (!dnt) {
              state.facebook.pixel = await import('react-facebook-pixel').then(
                (res) => res.default
              )
            }
            state.facebook.init = true
            if (!DEBUG) {
              state.facebook.pixel.init(FACEBOOK_ID, undefined, fbOptions)
            }
            log('initialized facebook pixel')
            state.facebook.pageView = (path: string) => {
              if (path !== state.facebook.lastPath) {
                log(`facebook pageview: ${path}`)
                state.facebook.pixel.pageView()
                state.facebook.lastPath = path
              }
            }
            state.facebook.track = (
              event: string,
              props: { [key: string]: unknown }
            ) => {
              log(`facebook track '${event}': `, props)
              state.facebook.pixel.track(event, props)
            }
            state.facebook.pageView(router.pathname)
          }
          router.events.on('routeChangeComplete', state.facebook.pageView)
        } catch (err) {
          console.error(err)
        }
        return () => {
          try {
            router.events.off('routeChangeComplete', state.facebook.pageView)
          } catch (err) {
            console.error(err)
          }
        }
      })()
    }, [router.pathname])

    return state.facebook
  }

  // taboola
  const useTaboola = () => {
    const router = useRouter()

    useEffect(() => {
      try {
        dnt = navigator?.doNotTrack === '1'
        if (typeof window !== 'undefined' && !state.taboola.init) {
          if (!dnt) {
            if (!document.getElementById('taboola-pixel')) {
              const taboolaScript = document.createElement('script')
              taboolaScript.async = true
              taboolaScript.src = `https://cdn.taboola.com/libtrc/unip/${TABOOLA_ID}/tfa.js`
              taboolaScript.id = 'taboola-pixel'
              taboolaScript.onload = () => {
                state.taboola.init = true
                state.taboola.pixel = window['_tfa'] || state.taboola.pixel
              }
              document.head.append(taboolaScript)
            }
          }
          log('initialized taboola pixel')
          state.taboola.pageView = (path: string) => {
            if (path !== state.taboola.lastPath) {
              log(`taboola pageview: ${path}`)
              state.taboola.pixel.push({
                notify: 'event',
                name: 'page_view',
                id: TABOOLA_ID,
              })
              state.taboola.lastPath = path
            }
          }
          state.taboola.track = (
            event: string,
            props: { [key: string]: unknown } = {}
          ) => {
            log(`taboola track '${event}': `, props)
            state.taboola.pixel.push({
              notify: 'event',
              name: event,
              id: TABOOLA_ID,
              ...props,
            })
          }
          state.taboola.pageView(router.pathname)
        }
        router.events.on('routeChangeComplete', state.taboola.pageView)
      } catch (err) {
        console.error(err)
      }
      return () => {
        try {
          router.events.off('routeChangeComplete', state.taboola.pageView)
          const taboolaScript = document.getElementById('taboola-script')
          if (taboolaScript) taboolaScript.remove()
        } catch (err) {
          console.error(err)
        }
      }
    }, [router.pathname])

    return state.taboola
  }

  // klaviyo
  const useKlaviyo = () => {
    const router = useRouter()
    let [user, isLoading] = [null, true]
    try {
      const { state } = useContext(UserContext)
      user = state.user
      isLoading = state.isLoading
    } catch {}

    useEffect(() => {
      try {
        dnt = navigator?.doNotTrack === '1'
        if (typeof window !== 'undefined' && !state.klaviyo.init) {
          if (!dnt) {
            if (!document.getElementById('klaviyo-pixel')) {
              const klaviyoScript = document.createElement('script')
              klaviyoScript.async = true
              klaviyoScript.src = `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${KLAVIYO_ID}`
              klaviyoScript.id = 'klaviyo-pixel'
              klaviyoScript.onload = () => {
                state.klaviyo.init = true
                state.klaviyo.pixel = window['_learnq'] || state.klaviyo.pixel
              }
              document.head.append(klaviyoScript)
            }
          }
          log('initialized klaviyo pixel')
          state.klaviyo.pageView = (path: string) => {
            if (path !== state.klaviyo.lastPath) {
              log(`klaviyo pageview: ${path}`)
              state.klaviyo.pixel.push({
                notify: 'event',
                name: 'page_view',
                id: KLAVIYO_ID,
              })
              state.klaviyo.lastPath = path
            }
          }
          state.klaviyo.track = (
            event: string,
            props: { [key: string]: unknown } = {}
          ) => {
            log(`klaviyo track '${event}': `, props)
            state.klaviyo.pixel.push(['track', event, props])
          }
          state.klaviyo.pageView(router.pathname)
        } else if (typeof window !== 'undefined' && !state.klaviyo.identified) {
          if (!isLoading && user) {
            state.klaviyo.pixel.push([
              'identify',
              {
                ['$email']: user.email,
                ['$first_name']: user.firstName,
                ['$last_name']: user.lastName,
              },
            ])
            state.klaviyo.identified = true
            log('klaviyo identified user')
          }
        }
        router.events.on('routeChangeComplete', state.klaviyo.pageView)
      } catch (err) {
        console.error(err)
      }
      return () => {
        try {
          router.events.off('routeChangeComplete', state.klaviyo.pageView)
          const klaviyoScript = document.getElementById('klaviyo-script')
          if (klaviyoScript) klaviyoScript.remove()
        } catch (err) {
          console.error(err)
        }
      }
    }, [router.pathname])

    return state.klaviyo
  }

  return {
    useFacebook,
    useTaboola,
    useKlaviyo,
  }
}

const tracking = create()

export default tracking

export const useFacebook = tracking.useFacebook
export const useTaboola = tracking.useTaboola
export const useKlaviyo = tracking.useKlaviyo
