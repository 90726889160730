import { useContext, useEffect, useState } from 'react'
import { GlobalContext } from 'contexts'
import { openDrawer } from 'services'
import { UserContext } from 'contexts/user-context'
import { UserActionType } from 'types/user-context'
import ShoppingCart from '@components/icons/ShoppingCart'
import { getCartAddOns } from '@utils/addOn'

export default function CartIcon({ navbar }: { navbar: Boolean }) {
  const { dispatch: globalDispatch } = useContext(GlobalContext)
  const {
    state: { cart },
    dispatch: userDispatch,
  } = useContext(UserContext)

  const [totalItems, setTotalItems] = useState(0)

  const addOns = getCartAddOns(cart?.line_items?.physical_items)

  const getTotalQuantity = (cart) => {
    let normalItemsQty = 0

    if (!cart) {
      setTotalItems(0)
      return
    }

    for (let key in cart?.line_items) {
      cart.line_items[key].forEach((item) => {
        if (!item?.options?.find((option) => option?.name === 'Add-Ons')) {
          normalItemsQty += item?.quantity || 1
        }
      })
    }

    const addOnsQty = addOns.reduce((acc, addOn) => {
      return acc + addOn.quantity
    }, 0)

    const finalQuantity = normalItemsQty + addOnsQty

    setTotalItems(finalQuantity)
    return finalQuantity
  }

  useEffect(() => {
    userDispatch({ type: UserActionType.SET_CART, payload: cart })
    getTotalQuantity(cart)
  }, [cart, userDispatch])

  return (
    <div>
      <div
        onClick={() => globalDispatch(openDrawer())}
        className="relative hover:cursor-pointer"
      >
        {!!totalItems ? (
          <ShoppingCart
            borderColor={navbar ? '#f1f0d8' : '#212721'}
            fillColor={navbar ? '#f1f0d8' : '#212721'}
          />
        ) : (
          <ShoppingCart
            borderColor={navbar ? '#f1f0d8' : '#212721'}
            fillColor={navbar ? '#00313c' : '#f9f9f0'}
          />
        )}
        {!!totalItems && (
          <div className="bg-pork-500 rounded-full absolute flex flex-col  items-center justify-center lg:w-4 lg:h-4 lg:left-5  2xs:w-4 2xs:h-4 2xs:left-5 -top-1">
            <span className="font-ringside-compressed-ssm-bold text-2xs 2xs:text-3xs">
              {totalItems}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
