import { CartItemType, CartType } from 'types/carts'
import api from '@services/api'
import _, { add } from 'lodash'
import { bundles } from '@components/AddOns/AddOnsData'

const getUpdatedCart = async () => {
  try {
    const { data } = await api.getCart()
    const cartData = data?.data || null
    return cartData
  } catch (error) {
    console.log(error)
  }
}

const OPTION_IDS = {
  '231': 179,
  '140': 180,
  '233': 182,
  '234': 184,
  '235': 185,
  '236': 186,
  '237': 187,
  '238': 188,
  '239': 189,
  '240': 190,
  '247': 193,
  '249': 195,
  '245': 191,
  '250': 196,
  '258': 227,
  '259': 228,
  '256': 225,
}

export const addAddOn = async ({
  addOn,
  subscriberOption,
}: {
  addOn: AddOn
  subscriberOption?: { option_id: number; option_value: string }
}): Promise<CartType> => {
  try {
    let payload = []
    addOn.items.map((item) => {
      const data = {
        product_id: item.id,
        quantity: item.quantity,
        option_selections: [
          {
            option_id: OPTION_IDS[item.id],
            option_value: addOn.id,
          },
        ],
      }
      if (
        !_.isNull(subscriberOption) &&
        !_.isUndefined(subscriberOption) &&
        !_.isEmpty(subscriberOption)
      ) {
        data.option_selections.push(subscriberOption)
      }
      payload.push(data)
    })
    await api.addItemsToCart(payload)
    return await getUpdatedCart()
  } catch (error) {
    console.log(error)
    return null
  }
}

export const removeAddOn = async (
  addOn: CartAddOn,
  newQuantity: number
): Promise<CartType> => {
  try {
    const quantityChange = newQuantity - addOn.quantity

    if (newQuantity === 0) {
      return await deleteAddOn(addOn)
    }

    if (quantityChange !== 0) {
      const itemsToEdit = addOn.items.slice()
      for (const item of itemsToEdit) {
        const newItemQuantity = (item.quantity / addOn.quantity) * newQuantity

        const data = {
          product_id: item.product_id,
          quantity: newItemQuantity,
          option_selections: [
            {
              option_id: OPTION_IDS[item.product_id],
              option_value: addOn.id,
            },
          ],
        }
        await api.editCartItem(item.id, data)
      }

      return getUpdatedCart()
    }
  } catch (error) {
    console.log(error)
    return null
  }
}

export const deleteAddOn = async (addOn: CartAddOn): Promise<CartType> => {
  try {
    const itemsToDelete = addOn.items.slice()
    for (const item of itemsToDelete) {
      await api.deleteCartItem(item.id)
    }
    return await getUpdatedCart()
  } catch (error) {
    console.log(error)
    return null
  }
}

export const getCartAddOns = (cartItems: CartItemType[]): CartAddOn[] => {
  let addOnBundles: CartAddOn[] = []
  try {
    if (!_.isEmpty(cartItems)) {
      Object.keys(bundles).forEach((key) => {
        let bundledItems = []
        let runningTotal = 0
        cartItems.forEach((item) => {
          const { options } = item
          if (!_.isEmpty(options)) {
            const addOnOption = options.find(
              (option) => option?.name === 'Add-Ons'
            )

            if (
              _.isEmpty(addOnOption) ||
              _.isEmpty(addOnOption?.value) ||
              _.isEmpty(addOnOption?.name)
            ) {
              return
            }

            const { name, value } = addOnOption

            if (!_.isEmpty(name) && name === 'Add-Ons' && !_.isEmpty(value)) {
              if (key === value) {
                bundledItems.push(item)
                runningTotal += item.extended_sale_price
              }
            }
          }
        })

        if (!_.isEmpty(bundledItems)) {
          addOnBundles.push({
            id: key,
            quantity:
              bundledItems.reduce((acc, curr) => acc + curr.quantity, 0) /
              bundles[key]['delimiter'],
            items: bundledItems,
            volume: bundles[key]['volume'],
            realPrice: runningTotal,
          })
        }
      })
    }
  } catch (error) {
    console.log(error)
  }

  let _addOnBundles = []
  if (addOnBundles?.length > 0) {
    _addOnBundles = validateCartAddOns(addOnBundles)
  }

  return _addOnBundles
}

const validateCartAddOns = (cartAddOns: CartAddOn[]): CartAddOn[] => {
  let _cartAddOns = []
  let index = 0
  for (const addOn of cartAddOns) {
    const bundleItem = bundles[addOn.id]
    if (_.isEmpty(bundleItem)) {
      continue
    }

    let addOnDeleted = false
    let addOnQuantity = null

    for (const item of bundleItem.items) {
      const cartItem = addOn.items.find(
        (addOnItem) => addOnItem.product_id == item.id
      )
      if (_.isEmpty(cartItem)) {
        deleteAddOn(addOn)
        addOnDeleted = true
        break
      }

      if (!addOnQuantity) {
        addOnQuantity = cartItem.quantity / item.quantity
      }

      if (
        !!addOnQuantity &&
        addOnQuantity !== cartItem.quantity / item.quantity
      ) {
        deleteAddOn(addOn)
        addOnDeleted = true
        break
      }
    }
    if (!addOnDeleted) {
      _cartAddOns.push(addOn)
    }

    index++
  }

  return _cartAddOns
}
export const getSubsInCart = (cart: CartType) => {
  let numSubs = 0
  cart?.line_items?.physical_items?.map((item) => {
    const subscription = item.options?.find(
      (option) => option.name === 'Sub Options'
    )?.value

    if (
      !_.isEmpty(subscription) &&
      !_.isUndefined(subscription) &&
      !_.isNull(subscription)
    ) {
      if (subscription.toLowerCase() !== 'one-time purchase') {
        numSubs += item.quantity
      }
    }
  })
  return numSubs
}

export type CartAddOn = {
  id?: string
  quantity: number
  items: CartItemType[]
  volume?: number
  realPrice?: number
}

export type AddOn = {
  id: string
  items: {
    id: string
    quantity: number
  }[]
  volume?: number
}
