const merchData = {
  '149': {
    name: 'Anti-Vegan Activist \n T-Shirt',
    description:
      'Ready to join the fight against Big Veggie™ and their crusade against meat? Then our Anti-Vegan Activist Tee is for you. Take a stand for American meat and look good while you do it.',
    details: [
      'Color: Crimson',
      'Blend: COMFORT COLORS HEAVYWEIGHT TEE, 6.1 OUNCE - 100% RING SPUN COTTON',
      'Screen-Printed Design',
      'Unisex Sizing',
      'Regular Fit',
      'Non-Woven Pinch Label',
    ],
    apparel: true,
    oldPrice: 33.99,
    showSinglePrice: true,
  },
  '155': {
    name: 'Eat Meat \n Shoot Guns \n T-Shirt',
    description:
      'Two of life’s greatest pleasures: eating meat and shooting guns. You can eat meat and then shoot guns, or shoot guns and then eat meat. Just don’t eat guns and shoot your meat. Whatever you do, at least do it in this cool shirt.',
    details: [
      'Color: True Navy',
      'Blend: COMFORT COLORS HEAVYWEIGHT TEE, 5 OUNCE - 100% COTTON',
      'Screen-Printed Design',
      'Unisex Sizing',
      'Regular Fit',
      'Non-Woven Pinch Label',
    ],
    apparel: true,
    oldPrice: 33.99,
    showSinglePrice: true,
  },
  '162': {
    name: 'The Classic \n T-Shirt',
    description:
      'Proud of the quality meat you buy and the local farms you support? You should be. The Classic exists to let you display your patriotic protein purchase and your love for American meat. It’s a staple piece for anyone in the Good Ranchers family.',
    details: [
      'Color: True Navy',
      'Blend: COMFORT COLORS HEAVYWEIGHT TEE, 5 OUNCE - 100% COTTON',
      'Screen-Printed Design',
      'Unisex Sizing',
      'Regular Fit',
      'Non-Woven Pinch Label',
    ],
    apparel: true,
    outOfStockFormId: 'ST6HWG',
    oldPrice: 29.99,
    showSinglePrice: true,
  },
  '169': {
    name: 'Ranchers Sunset \n T-Shirt',
    description:
      'Not everyone gets to look as cool as a cowboy, but at least you can represent our beloved cattle ranchers in style. Snag yours today to look your best walking off into the western sunset.',
    details: [
      'Color: Rose Quartz',
      'Blend: USA MADE MINERAL DYED SHORT SLEEVE TEE,6.5 OUNCE - 100% COTTON',
      'Screen-Printed Design',
      'Unisex Sizing',
      'Regular Fit',
      'Non-Woven Pinch Label',
    ],
    apparel: true,
    oldPrice: 35.99,
    showSinglePrice: true,
  },
  '175': {
    name: ' Eat Your Meaties \n T-Shirt',
    description:
      'Skip the breakfast aisle, Meaties is the dinner of champions. Display your love of beef to everyone you meat with this nutritious and delicious shirt.',
    details: [
      'Color: Vintage Black',
      'Blend: USA MADE PIGMENT DYED SHORT SLEEVE TEE, 6.5 OUNCE - 100% COTTON',
      'Screen-Printed Design',
      'Unisex Sizing',
      'Regular Fit',
      'Non-Woven Pinch Label',
    ],
    apparel: true,
    oldPrice: 43.99,
    showSinglePrice: true,
  },
  '182': {
    name: 'The Perfect Cut T-Shirt',
    description:
      'This shirt will fit you to a T…bone. Represent the fab four of steaks (and your four favorite meals) all at the same time. If you’re a beef eater, then this shirt is for you. Get The Perfect Cut to complete your perfect fit.',
    details: [
      'Color: Black',
      'Blend: USA MADE VOLUNTEER KNITWEAR TRI-BLEND TEE, 4.5 OUNCE - 50% COTTON, 25% POLYSTER, 25% RAYON ',
      'Screen-Printed Design',
      'Unisex Sizing',
      'Regular Fit',
      'Non-Woven Pinch Label',
    ],
    apparel: true,
    oldPrice: 39.99,
    showSinglePrice: true,
  },
  '189': {
    name: 'USA Proud \n T-Shirt',
    description:
      'America is the best, has the best meat, and has a pretty sick flag. That’s why we made this shirt. You can sport Old Glory, while declaring that every piece of meat you get delivered is truly 100% American.',
    details: [
      'Color: Gray Heather',
      'Blend: USA MADE VOLUNTEER KNITWEAR TRI-BLEND TEE, 4.5 OUNCE - 50% COTTON, 25% POLYSTER, 25% RAYON',
      'Screen-Printed Design',
      'Unisex Sizing',
      'Regular Fit',
      'Non-Woven Pinch Label',
    ],
    apparel: true,
    oldPrice: 39.99,
    showSinglePrice: true,
  },
  '196': {
    name: 'Camo Carnivore Hoodie',
    description:
      'Show your food chain dominance while camouflaging yourself from would-be carnivorous competitors. Be a proud protein consumer and look great sitting at the top of the food chain.',
    details: [
      'Color: Forest Camo',
      'Blend: MIDWEIGHT HOODIE (PAKISTAN), 8.5 OUNCE - 80% COTTON, 20% POLYESTER',
      'Screen-Printed Design',
      'Unisex Sizing',
      'Regular Fit',
    ],
    apparel: true,
    oldPrice: 59.99,
    showSinglePrice: false,
  },
  '203': {
    name: 'USA Proud Hoodie',
    description:
      'Love the shirt? Then get the hoodie too. One of our most popular designs comes in a cozy, lightweight hoodie so that you can wear your Good Ranchers merch in any weather.',
    details: [
      'Color: Gray Heather',
      'Blend: MIDWEIGHT HOODIE (PAKISTAN), 8.5 OUNCE - 80% COTTON, 20% POLYESTER',
      'Screen-Printed Design',
      'Unisex Sizing',
      'Regular Fit',
    ],
    apparel: true,
    oldPrice: 44.99,
    showSinglePrice: false,
  },
  '210': {
    name: 'GR Executive Polo',
    description:
      'Bring the style of American meat to your office, the golf course, or anywhere where you need to look as good as our products taste with our breathable—AKA beefable—polo. ',
    details: [
      'Color: Black ',
      'Blend: ADIDAS PERFORMANCE POLO, 3.8 OUNCE - 100% RECYCLED POLYESTER',
      'Unisex Sizing',
      'Regular Fit',
    ],
    apparel: true,
    oldPrice: 54.99,
    showSinglePrice: false,
  },
  '217': {
    name: 'North Face 1/4 Zip',
    description:
      'Have you ever purchased something so soft it makes marshmallows insecure? Well you will when you snag your quarter-zip fleece today.',
    details: [
      '100% Cotton',
      'Classic Crew Neck',
      'Screen-Printed Design',
      'Unisex Sizing',
      'Regular Fit',
    ],
    apparel: true,
    oldPrice: false,
    showSinglePrice: false,
  },
  '224': {
    name: 'The Perfect Cut Apron',
    description:
      'Rep the fab four of steaks while you cook them. Keep yourself free of stains and spills while you whip up delicious, 100% American meat in your perfect cut apron.',
    details: [
      'Color: Duck/Stone',
      'Blend: CANVAS TWO-POCKET BIB APRON',
      '100% COTTON CANVAS',
      'Screen-Printed Design',
      'Non-Woven Pinch Label',
      'Did we mention it has pockets?',
    ],
    apparel: false,
    oldPrice: 32.99,
    showSinglePrice: false,
  },
  '225': {
    name: 'Steak Emoji Dad Hat',
    description:
      'Don’t just text it, sport it. You don’t have to be a dad to look good in this hat—steak looks good on everyone',
    details: [
      'Color: Vintage Black',
      'Blend: UNSTRUCTURED PIGMENT DYED CAP, 100% COTTON',
      'Unisex',
      'Embroidered Steak Emoji',
    ],
    apparel: false,
    oldPrice: 29.99,
    showSinglePrice: true,
  },
  '226': {
    name: 'The Manley Cup',
    description:
      'A great gift to yourself or your friend Stanley! Keep your drinks at the temperature you like for hours on end in the convenient and durable Manley cup.',
    details: [
      'Color: Black',
      '40 OUNCE Capacity',
      'STAINLESS STEEL TUMBLER',
      'Cool Bull on the back',
    ],
    apparel: false,
    oldPrice: 39.99,
    showSinglePrice: true,
  },
  '227': {
    name: 'Good Ranchers Trucker Hat',
    description:
      'Party in the front, breathable mesh in the back. Our trucker hat is perfect for the inside or outside and is a super comfortable fit all around.',
    details: [
      'Color: Khaki',
      'Blend: USA MADE STRUCTURED TRUCKER CAP',
      'COTTON/POLYESTER FRONT',
      'POLYESTER MESH BACK',
      'Pairs great with a Ham Radio',
    ],
    apparel: false,
    oldPrice: 42.99,
    showSinglePrice: true,
  },
  '228': {
    name: 'Disposable Cutting Board',
    description: '',
    details: [],
    apparel: true,
  },
  '229': {
    name: 'Good Ranchers Pinch Label',
    description: '',
    details: [],
    apparel: true,
  },
  '230': {
    name: 'Good Ranchers Sticker Hang Tag',
    description: '',
    details: [],
    apparel: true,
  },
}

export default merchData
