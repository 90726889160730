import React, { useEffect } from 'react'
import { CouponStatus } from 'utils/coupon'
import { useState, useContext } from 'react'
import { UserContext } from 'contexts/user-context'

const CouponInputStatus = ({
  status,
  code,
}: {
  status: CouponStatus
  code?: string
}) => {
  const {
    state: { cart },
  } = useContext(UserContext)

  const [message, setMessage] = useState<string>(null)

  const date = new Date().toISOString().slice(0, 10)
  const cyberMondayDate = '2023-11-27'

  useEffect(() => {
    switch (status) {
      case CouponStatus['VALID']:
        setMessage(`Code "${code}" applied!`)
        break
      case CouponStatus['INVALID']:
        if (date === cyberMondayDate) {
          setMessage(
            `Code "${code}" is invalid. Try code "DIGITAL" - (Discount codes not applicable on gift boxes or merch)`
          )
        } else {
          setMessage(`Code "${code}" is invalid.`)
        }
        break
      case CouponStatus['DOES NOT APPLY']:
        if (date === cyberMondayDate) {
          setMessage(
            `Code "${code}" does not apply. Try code "DIGITAL" instead! - (Discount codes not applicable on gift boxes or merch)`
          )
        } else {
          setMessage(`Code "${code}" does not apply to your cart.`)
        }
        break
      case CouponStatus['LOADING']:
        setMessage('Loading...')
        break
      case CouponStatus['EMPTY']:
        setMessage(null)
        break
    }
  }, [status, code])

  return status === CouponStatus['LOADING'] ? (
    <p className="font-ringside-ssm-book italic text-[11px] mb-4 mt-0">
      Loading...
    </p>
  ) : (
    message && code && code !== '' && (
      <p
        className={`font-ringside-ssm-book ${
          status === CouponStatus.VALID ? 'text-green-700' : 'text-beef-500'
        } italic text-[11px] mb-4 mt-0`}
      >
        {message}
      </p>
    )
  )
}

export default CouponInputStatus
