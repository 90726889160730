import { CartAddOn, deleteAddOn } from '@utils/addOn'

const AddOnsCartItem = ({
  addOn,
  handleAddOnDelete,
  quantity,
  totalPrice,
  desc,
  showDelete,
  title,
  image,
  product,
}: {
  addOn: CartAddOn
  handleAddOnDelete: Function
  quantity: number
  totalPrice: number
  updatedPrice: number
  desc: string
  showDelete: boolean
  title: string
  image: string
  product?: any
}) => {
  return (
    <div className="flex flex-row items-end lg:gap-[10px] md:gap-[10px] sm:gap-[6px] gap-[6px]">
      <div className="flex flex-row w-auto">
        <img
          src={image}
          className="object-cover lg:h-[96px] md:h-[86px] h-[74px] lg:w-[96px] md:w-[86px] w-[74px]"
          alt={product?.name}
        />
      </div>
      <div className="flex flex-col w-full justify-end lg:gap-[10px] md:gap-[10px] sm:gap-[6px] gap-[6px] font-ringside-ssm-medium lg:text-[16px] lg:leading-[16px] md:text-[16px] md:leading-[16px] text-[13px] leading-[13px]">
        <div className="flex flex-row justify-between gap-[4px] w-full pb-2">
          <div className="font-[700] capitalize relative top-3">{title}</div>
          {showDelete && (
            <button
              onClick={() => handleAddOnDelete(addOn)}
              className="border-none "
            >
              <img
                draggable={false}
                src="/img/rebrand/cart_delete.svg"
                alt="delete item svg"
              />
            </button>
          )}
        </div>

        <div className="flex flex-row gap-[4px] pb-1 justify-between w-full items-end">
          <div className="flex flex-row gap-2 w-full items-end text-start">
            <div className="flex flex-col lg:gap-2 gap-1 items-center justify-end font-[400] lg:text-[10px] lg:leading-[10px] md:text-[10px] md:leading-[10px] sm:text-[8px] sm:leading-[8px] text-[8px] leading-[8px]">
              <div>QTY</div>
              <div className="text-4xs lg:text-[13px] ml-0.5">{quantity}</div>
            </div>
            <div className="font-[400] lg:text-[13px] pt-4 lg:pt-[24px] lg:leading-[20px] md:text-[12px] md:leading-[13px] sm:text-[10px] sm:leading-[10px] text-[10px] leading-[10px]">
              {desc}
            </div>
            {/* <span className="font-[400] text-start lg:text-[10px] lg:leading-[10px] md:text-[10px] md:leading-[10px] sm:text-[8px] sm:leading-[8px] text-[8px] leading-[8px] font-ringside-ssm-book italic text-deep-400">
                  *Specific flavors may vary
                </span> */}
          </div>
          <div className="font-[700]  lg:text-[13px] lg:leading-[13px] md:text-[13px] md:leading-[13px] sm:text-[10px] sm:leading-[10px] text-[10px] leading-[10px]">
            ${totalPrice.toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddOnsCartItem
