const CartItemSubscription = ({
  cartLoading,
  selectedSubscription,
  handleSubscriptionChange,
  options,
  isAddOn,
  id,
}: {
  cartLoading: boolean
  selectedSubscription: string
  handleSubscriptionChange: Function
  options: any
  isAddOn: boolean
  id: number
}) => {
  const handleSubscriptionAndAddToCart = (event) => {
    handleSubscriptionChange(event)
  }

  const isFreeGift = id === 134 || id === 131 || id == 245 || id == 246

  return (
    <div className="group inline-block  w-full items-center justify-end hover:cursor-pointer">
      <div className="flex  flex-row lg:h-[24px] md:h-[24px] sm:h-[20px] h-[20px] w-full items-center justify-between text-center ">
        <span className="pl-[2px] bottom-0 font-[400] lg:text-[13px] lg:leading-[13px] md:text-[13px] md:leading-[13px] sm:text-[10px] sm:leading-[10px] text-[10px] leading-[10px]">
          {!isAddOn && id != 232 && id != 241 && id != 242 && id != 243 && !isFreeGift ? (
            <>
              <select
                className="subscription-dropdown  text-4xs lg:text-[13px] w-[120px] lg:w-[150px] capitalize lg:h-[24px] lg:mt-2 text-black"
                value={selectedSubscription}
                onChange={handleSubscriptionAndAddToCart}
                disabled={cartLoading || isAddOn}
              >
                {options?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.label}
                  </option>
                ))}
              </select>
            </>
          ) : id == 241 || id == 242 || id == 243 ? (
            <div className="flex flex-col justify-center text-left pl-2 text-4xs lg:text-[13px] w-[120px] lg:w-[150px] capitalize lg:h-[24px] lg:mt-2 text-black">
              Gifting Box!
            </div>
          ) : isFreeGift ?
          <div className="flex flex-col justify-center text-left pl-2 text-4xs lg:text-[13px] w-[120px] lg:w-[150px] capitalize lg:h-[24px] lg:mt-2 text-black">
              Free Gift For A Year!
            </div> : (
            <div className="flex flex-col justify-center text-left pl-2 text-4xs lg:text-[13px] w-[120px] lg:w-[150px] capitalize lg:h-[24px] lg:mt-2 text-black">
              Special Item!
            </div>
          )}
        </span>
      </div>
    </div>
  )
}

export default CartItemSubscription
