import React, { useEffect, useState, Dispatch, SetStateAction } from 'react'
import Swal from 'sweetalert2'
import { applyCoupon, CouponStatus } from '@utils/coupon'
import CouponInputStatus from './CouponInputStatus'
import Cookies from 'js-cookie'
import _ from 'lodash'
import { COUPON_CODE } from 'constants/cookies'
import { CartType } from 'types/carts'
import { chickenCoupons, wingCoupons } from '@components/cart/GWPCouponList'

const CouponInput = ({
  cart,
  refetchCart,
  active,
  setActive,
  setStoredCoupon,
  initialCode,
  activeCoupon,
  getSubsInCart,
  setCurrentGwpProductId,
}: {
  cart: CartType
  refetchCart: () => Promise<void>
  active: boolean
  setActive: Dispatch<SetStateAction<boolean>>
  setStoredCoupon: Dispatch<SetStateAction<string>>
  initialCode?: string
  activeCoupon?: string
  getSubsInCart?: () => number
  setCurrentGwpProductId: Dispatch<SetStateAction<number>>
}) => {
  const [rawInputValue, setRawInputValue] = useState<string>('')
  const [inputCode, setInputCode] = useState<string>(initialCode || '')
  const [couponInputStatus, setCouponInputStatus] = useState<CouponStatus>(
    active ? CouponStatus['VALID'] : CouponStatus['EMPTY']
  )

  useEffect(() => {
    if (
      inputCode &&
      couponInputStatus === CouponStatus['VALID'] &&
      _.isNull(activeCoupon)
    ) {
      setCouponInputStatus(CouponStatus['DOES NOT APPLY'])
    }

    if (
      inputCode &&
      couponInputStatus === CouponStatus['DOES NOT APPLY'] &&
      !_.isNull(activeCoupon) &&
      inputCode.toLocaleLowerCase() === activeCoupon?.toLocaleLowerCase()
    ) {
      setCouponInputStatus(CouponStatus['VALID'])
    }
  }, [activeCoupon])

  /**
   * Checks if any of the given SKUs are in the cart.
   *
   * @returns {boolean}
   */
  const isSKUInCart = (sku: string): boolean => {
    const physicalItems = cart?.line_items?.physical_items

    if (physicalItems) {
      for (const item of physicalItems) {
        if (sku === item.sku) {
          return true
        }
      }
    }
    return false
  }

  const handleCodeSubmit = () => {
    const handleApplyCode = async (code: string) => {
      const subs = getSubsInCart()

      if (
        (!subs && wingCoupons.includes(code)) ||
        (!subs && chickenCoupons.includes(code))
      ) {
        setCouponInputStatus(CouponStatus['DOES NOT APPLY'])
        return
      }

      const containsGameDay = isSKUInCart('GAMEDAYSMALL-GA')
      if (containsGameDay) {
        setCouponInputStatus(CouponStatus['DOES NOT APPLY'])
        return
      }

      const containsWeeklyEssentials = isSKUInCart('WEEKLYESSENTIALS')
      if (containsWeeklyEssentials) {
        if (!wingCoupons.includes(code) && !chickenCoupons.includes(code)) {
          setCouponInputStatus(CouponStatus['DOES NOT APPLY'])
          return
        }
      }

      const newStatus = await applyCoupon(code)
      setCouponInputStatus(newStatus)
      const couponFromCookies = Cookies.get(COUPON_CODE)
      if (newStatus === CouponStatus.VALID) {
        setStoredCoupon(code)
        if (couponFromCookies !== code) {
          Cookies.set(COUPON_CODE, code, {
            expires: 1 / 48,
            path: '/',
          })
        }
        setActive(true)
        refetchCart()
      } else if (
        cart?.coupons?.length === 0 &&
        newStatus === CouponStatus['DOES NOT APPLY']
      ) {
        setStoredCoupon(code)
        if (couponFromCookies !== code) {
          Cookies.set(COUPON_CODE, code, {
            expires: 1 / 48,
            path: '/',
          })
        }
      }
    }

    if (!cart) {
      Swal.fire(
        'Your plate is empty!',
        'Please add items to your cart before applying a coupon.',
        'question'
      )
      return
    }

    const _inputCode = rawInputValue.toLocaleLowerCase()
    if (chickenCoupons.includes(_inputCode)) {
      setCurrentGwpProductId(131)
    }
    if (wingCoupons.includes(_inputCode)) {
      setCurrentGwpProductId(261)
    }
    setCouponInputStatus(CouponStatus['LOADING'])
    setInputCode(_inputCode)
    handleApplyCode(_inputCode)
  }

  return (
    <>
      <div className="flex flex-row mb-2 gap-2">
        <input
          className="w-full pl-2 text-[16px]"
          type="text"
          placeholder="COUPON CODE"
          value={rawInputValue}
          onChange={(e) => {
            setRawInputValue(e.target.value)
          }}
        />

        <button
          disabled={rawInputValue === '' || rawInputValue.length < 3}
          onClick={() => handleCodeSubmit()}
          className={`btn-primary-tw content-center align-middle justify-center text-sm p-1 ${
            rawInputValue === '' || rawInputValue.length < 3
              ? '!cursor-not-allowed bg-gray-300 border-gray-300'
              : ''
          } `}
        >
          Apply
        </button>
      </div>

      <CouponInputStatus status={couponInputStatus} code={inputCode} />
    </>
  )
}

export default CouponInput
