import { useEffect, useState } from 'react'
import { Product } from 'types/products'

const MerchCartItemVariant = ({
  handleMerchSizeChange,
  product,
  variant_id,
}: {
  handleMerchSizeChange: Function
  product: Product
  variant_id: string | number
}) => {
  const [selectedSize, setSelectedSize] = useState(variant_id)

  const handleSubscriptionAndAddToCart = (event) => {
    const newSize = event.target.value
    setSelectedSize(newSize)
    handleMerchSizeChange(event)
  }

  const [uniqueSizes, setUniqueSizes] = useState([])
  const [isSelectOpen, setIsSelectOpen] = useState(false)

  useEffect(() => {
    const sizes = product?.variants
      .map((variant) => ({
        label: variant.option_values[0]?.label,
        id: variant.id,
      }))
      .filter(Boolean)

    const filteredSizes = [...new Set(sizes)]

    setUniqueSizes(filteredSizes)
  }, [product?.variants, variant_id])

  useEffect(() => {
    setSelectedSize(variant_id)
  }, [variant_id])

  return (
    <div className="group inline-block w-full items-center justify-end hover:cursor-pointer">
      <div className="flex  flex-row lg:h-[30px] md:h-[32px] sm:h-[20px] h-[28px] w-full items-center justify-between text-center ">
        <span className="pl-[2px] bottom-0 font-[400] lg:text-[13px] lg:leading-[13px] md:text-[13px] md:leading-[13px] sm:text-[10px] sm:leading-[10px] text-[10px] leading-[10px]">
          {!!uniqueSizes?.length ? (
            <div className="flex text-sm mt-2">
              <select
                onFocus={() => setIsSelectOpen(true)}
                onBlur={() => setIsSelectOpen(false)}
                id="size"
                value={selectedSize}
                name="size"
                className="subscription-dropdown  text-4xs lg:text-[13px] w-[120px] lg:w-[150px] capitalize lg:h-[24px] lg:mt-2 text-black"
                onChange={handleSubscriptionAndAddToCart}
              >
                Size:
                <option value={selectedSize}>
                  Size:{' '}
                  {uniqueSizes.find((size) => size.id === selectedSize)?.label}
                </option>
              </select>
            </div>
          ) : (
            <div className="flex flex-col justify-center text-left pl-2 text-4xs lg:text-[13px] w-[120px] lg:w-[150px] capitalize lg:h-[24px] lg:mt-2 text-black">
              Special Item!
            </div>
          )}
        </span>
      </div>
    </div>
  )
}

export default MerchCartItemVariant
