export default function checkIfMerch(product) {
  if (!product || !product.categories) {
    return false;  // or handle however you'd like
  }
  return product.categories.some((obj) => obj === 32);
}

export function checkIfGiftBox(product) {
  if (!product || !product.categories) {
    return false;  // or handle however you'd like
  }
  return product.categories.some((obj) => obj === 37);
}


export  function checkIfMeatBox(product){
  if(!product || !product.categories){
    return false;
  }
  return product.categories.some((obj) => obj === 26 || 38);
}