import { useEffect, useContext, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import CartItem from '@components/cart/CartItem/CartItem'
import GiftCertificateCartItem from '@components/cart/CartItem/GiftCertificateCartItem'
import { GlobalContext } from 'contexts'
import { closeDrawer } from 'services'
import { UserContext } from 'contexts/user-context'
import api from 'services/api'
import { UserActionType } from 'types/user-context'
import { useFacebook, useTaboola, useKlaviyo } from 'services/tracking'
import { CartItemType, GiftCertificateCartItemType } from 'types/carts'
import { formatMoney, getAllItems } from 'utils'
import CartIcon from '@components/cart/CartToggle'
import Coupon from './Coupon/Coupon'
import EmptyPlate from './CartItem/EmptyPlate'
import CartGWPItem from './CartItem/CartGWPItem'
import _ from 'lodash'
import { bundles } from '@components/AddOns/AddOnsData'
import AddOnsCartItem from '@components/AddOns/AddOnsCartItem'
import * as addOnUtils from '@utils/addOn'
import { wingCoupons, chickenCoupons } from './GWPCouponList'

declare const window: any

export const Cart = () => {
  const {
    dispatch: globalDispatch,
    state: { drawerOpen, overlayActive },
  } = useContext(GlobalContext)
  const {
    state: { user, isLoading, cart },
    dispatch: userDispatch,
  } = useContext(UserContext)

  const router = useRouter()
  const facebookPixel = useFacebook()
  const taboolaPixel = useTaboola()
  const klaviyoPixel = useKlaviyo()

  const [loading, setLoading] = useState(false)
  const [numProducts, setNumProducts] = useState(0)
  const [numSubs, setNumSubs] = useState(0)
  const [numSubscribableBoxes, setNumSubscribableBoxes] = useState(0)
  const [finalPrice, setFinalPrice] = useState(cart?.cart_amount)
  const [isThereMeatInTheCart, setIsThereMeatInTheCart] = useState(false)
  const [isBoxInCart, setIsBoxInCart] = useState(true)
  const [isSubscriptionInCart, setIsSubscriptionInCart] = useState(false)
  const [totalSavings, setTotalSavings] = useState('0')
  const [addOns, setAddOns] = useState([])
  const [gwpProductId, setGwpProductId] = useState(null)

  const removeOldSubscriptionOptions = async () => {
    let hasSubscriptionOptions = false
    const subIdsToRemove = []
    cart?.line_items?.physical_items?.map((item) => {
      const subscription = item.options?.find(
        (option) => option.name === 'Subscription Options'
      )?.value

      if (
        !_.isEmpty(subscription) &&
        !_.isUndefined(subscription) &&
        !_.isNull(subscription)
      ) {
        hasSubscriptionOptions = true
        subIdsToRemove.push(item.id)
      }
    })
    if (hasSubscriptionOptions) {
      // remove those items from the cart
      for (const id of subIdsToRemove) {
        const cartRes = await api.deleteCartItem(id)
        const { data = null } = cartRes?.data || {}
        userDispatch({ type: UserActionType.SET_CART, payload: data })
      }
    }
  }

  const updateCart = async () => {
    let boxItemsCount = 0
    cart?.line_items?.physical_items?.forEach((item) => {
      if (item.product_id !== 140 && item.product_id !== 148) {
        boxItemsCount = boxItemsCount + item.quantity
      }
    })
    setNumProducts(boxItemsCount)
  }

  const getCart = async () => {
    try {
      const cartRes = await api.getCart()
      const { data } = cartRes.data

      userDispatch({ type: UserActionType.SET_CART, payload: data })
    } catch (e) {
      console.error('Failed to get cart: ', e)
      userDispatch({ type: UserActionType.SET_CART, payload: null })
    }
  }

  const BaconButterLineItem = () => (
    <div className="flex items-center gap-2 font-ringside-ssm-book ">
      <img src="/img/cart/bacon-butter.png" />
      Free Bacon Butter included
    </div>
  )

  const checkGWP = () => {
    const isChickenInCart = cart?.line_items?.physical_items?.some(
      (item) => item.product_id === 131
    )
    const isWingInCart = cart?.line_items?.physical_items?.some(
      (item) => item.product_id === 261
    )
    const chickenGwp = cart?.line_items?.physical_items?.find(
      (item) => item.product_id === 131
    )
    const wingGwp = cart?.line_items?.physical_items?.find(
      (item) => item.product_id === 261
    )

    const coupon = cart?.coupons[0]?.code

    if (wingCoupons.includes(coupon?.toLowerCase()) && isChickenInCart) {
      handleItemDelete(chickenGwp)
    }
    if (chickenCoupons.includes(coupon?.toLowerCase()) && isWingInCart) {
      handleItemDelete(wingGwp)
    }
  }

  useEffect(() => {
    removeOldSubscriptionOptions()
    getSubItems()
    getAddOns()
    setLoading(true)
    checkIfBoxInCart()
    updateCart()
    checkAddOnsCriteria()
    calculateTotalSavings()
    setFinalPrice(cart?.cart_amount)
    setLoading(false)
    checkGWP()
  }, [cart, isThereMeatInTheCart])

  const getAddOns = () => {
    const addOns = addOnUtils.getCartAddOns(cart?.line_items?.physical_items)
    setAddOns(addOns)
  }

  useEffect(() => {
    if (!overlayActive) {
      globalDispatch(closeDrawer())
    }
  }, [drawerOpen, overlayActive])

  useEffect(() => {
    if (user && !isLoading && drawerOpen) {
      getCart()
    }
  }, [user, isLoading, drawerOpen])

  const getSubItems = () => {
    let _numSubs = 0
    let _numSubscribableBoxes = 0
    cart?.line_items?.physical_items?.map((item) => {
      const subscription = item.options?.find(
        (option) => option.name === 'Sub Options'
      )?.value

      if (
        !_.isEmpty(subscription) &&
        !_.isUndefined(subscription) &&
        !_.isNull(subscription)
      ) {
        _numSubscribableBoxes += item.quantity
        if (subscription.toLowerCase() !== 'one-time purchase') {
          _numSubs += item.quantity
        }
      }
    })
    setNumSubs(_numSubs)
    setNumSubscribableBoxes(_numSubscribableBoxes)
  }

  const updateGWPItemQuantity = async ({
    numSubs,
    gwpProductId,
  }: {
    numSubs: number
    gwpProductId: number
  }) => {
    try {
      const item = cart?.line_items?.physical_items?.find(
        (item) => item.product_id === gwpProductId
      )
      const itemId = item?.id

      if (numSubs === 0) {
        if (item) {
          const cartRes = await api.deleteCartItem(itemId)
          const { data: cartData } = cartRes.data
          userDispatch({ type: UserActionType.SET_CART, payload: cartData })
        }
      } else {
        if (item?.quantity !== numSubs) {
          const promoItemData: any = {
            product_id: gwpProductId,
            quantity: numSubs,
          }
          const cartRes = !!itemId
            ? await api.editCartItem(itemId, promoItemData)
            : await api.addItemToCart(promoItemData)
          const { data = null } = cartRes.data || {}
          userDispatch({ type: UserActionType.SET_CART, payload: data })
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const checkAddOnsCriteria = async () => {
    const bundlesAddOnsIds = Object?.values(bundles)
      .flatMap((bundle) => bundle?.items)
      .map((item) => item?.id)

    const matchingCartItems = cart?.line_items?.physical_items?.filter(
      (item) =>
        bundlesAddOnsIds?.includes(item?.product_id?.toString()) &&
        item?.options?.some((option) => option?.name === 'Add-Ons')
    )

    const hasBox =
      cart?.line_items?.physical_items.some(
        (item) =>
          item.options &&
          item.options.some((option) => option.name === 'Sub Options')
      ) ||
      cart?.line_items?.physical_items.some(
        (item) =>
          item.product_id === 232 ||
          item.product_id === 241 ||
          item.product_id === 242 ||
          item.product_id === 243 ||
          item.product_id === 254
      )

    if (!hasBox || cart?.base_amount <= 0) {
      matchingCartItems?.forEach((item) => {
        handleItemDelete(item)
      })
    }
  }

  const calculateTotalSavings = () => {
    try {
      let totalSavings = 0
      cart?.line_items?.physical_items?.forEach((item) => {
        const savings = (item.original_price - item.list_price) * item.quantity
        totalSavings += savings
      })
      cart?.coupons?.forEach((coupon) => {
        totalSavings += coupon.discounted_amount
      })
      setTotalSavings(totalSavings.toFixed(2))
    } catch (error) {
      console.error('ERROR calculating total savings - ', error)
    }
  }

  const checkIfBoxInCart = () => {
    if (cart?.line_items?.physical_items) {
      const hasBox =
        cart?.line_items?.physical_items.some(
          (item) =>
            item.options &&
            item.options.some((option) => option.name === 'Sub Options')
        ) ||
        cart?.line_items?.physical_items.some(
          (item) =>
            item.product_id === 232 ||
            item.product_id === 241 ||
            item.product_id === 242 ||
            item.product_id === 243 ||
            item.product_id === 254
        )

      const hasSubscriptionOption = cart?.line_items?.physical_items.some(
        (item) =>
          item.options &&
          item.options.some((option) => option.name === 'Sub Options')
      )

      setIsSubscriptionInCart(hasSubscriptionOption)
      setIsBoxInCart(hasBox)
      setIsThereMeatInTheCart(hasBox)
    } else {
      setIsThereMeatInTheCart(false)
      setIsBoxInCart(false)
      setIsSubscriptionInCart(false)
    }
  }

  const handleItemDelete = async (item: CartItemType) => {
    try {
      setLoading(true)
      const items = cart.line_items?.physical_items?.filter(
        (lineItem) => String(lineItem.id) !== String(item.id)
      )
      const cartRes = await api.deleteCartItem(item.id)
      const { data = null } = cartRes?.data || {}
      userDispatch({ type: UserActionType.SET_CART, payload: data })

      // tracking logic
      pixelTracking(item, items)

      if (router.pathname === '/checkout') {
        router.reload()
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const handleAddOnDelete = async (addOn: addOnUtils.CartAddOn) => {
    try {
      setLoading(true)
      const cartRes = await addOnUtils.deleteAddOn(addOn)
      userDispatch({ type: UserActionType.SET_CART, payload: cartRes })

      if (router.pathname === '/checkout') {
        router.reload()
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const pixelTracking = (item, items) => {
    const subscription =
      item.options?.filter?.((option) => option.name === 'every')?.[0]?.value ||
      'one-time purchase'
    facebookPixel.track('AddToCart', {
      content_ids: [item.sku],
      content_name: item.name,
      contents_type: 'product',
      contents: [
        {
          id: item.sku,
          quantity: item.quantity * -1,
          content_name: item.name,
          unit_price: item.list_price,
          currency: 'usd',
          subscription,
        },
      ],
      currency: 'usd',
      value: item.list_price * item.quantity * -1,
    })
    taboolaPixel.track('AddToCart')
    klaviyoPixel.track('Added to Cart', {
      $value: item.list_price * item.quantity * -1,
      AddedItemProductName: item.name,
      AddedItemProductID: item.product_id,
      AddedItemSKU: item.sku,
      AddedItemURL: item.url.replace(
        /https?:\/\/store\.goodranchers/,
        'https://www.goodranchers'
      ),
      AddedItemPrice: item.list_price,
      AddedItemQuantity: item.quantity * -1,
      MetaData: {
        subscription,
      },
      CheckoutURL: 'https://www.goodranchers.com/checkout',
      Items: items.map((listItem) => ({
        ProductID: listItem.product_id,
        SKU: listItem.sku,
        ProductName: listItem.name,
        Quantity: listItem.quantity,
        ItemPrice: listItem.list_price,
        RowTotal: listItem.list_price * listItem.quantity,
        ProductURL: listItem.url.replace(
          'store.goodranchers',
          'www.goodranchers'
        ),
        MetaData: {
          subscription:
            listItem.options?.filter?.((option) => option.name === 'every')?.[0]
              ?.value || 'one-time purchase',
        },
      })),
    })
  }

  const handleGiftCertificateItemDelete = async (
    item: GiftCertificateCartItemType
  ) => {
    try {
      setLoading(true)
      const cartRes = await api.deleteCartItem(item.id)
      const { data = null } = cartRes?.data || {}
      userDispatch({ type: UserActionType.SET_CART, payload: data })
      // tracking logic
      facebookPixel.track('AddToCart', {
        content_ids: [item.id],
        content_name: item.name,
        contents_type: 'product',
        contents: [
          {
            id: item.id,
            quantity: item.quantity * -1,
            content_name: item.name,
            unit_price: item.list_price,
            currency: 'usd',
          },
        ],
        currency: 'usd',
        value: item.list_price * item.quantity * -1,
      })
      taboolaPixel.track('AddToCart')
      klaviyoPixel.track('Added to Cart', {
        $value: item.list_price * item.quantity * -1,
        AddedItemProductName: item.name,
        AddedItemProductID: item.id,
        AddedItemSKU: item.id,
        AddedItemPrice: item.list_price,
        AddedItemQuantity: item.quantity * -1,
        CheckoutURL: 'https://www.goodranchers.com/checkout',
      })
      if (router.pathname === '/checkout') {
        router.reload()
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const beginCheckout = async (item) => {
    const products = cart.line_items?.physical_items?.filter(
      (lineItem) => String(lineItem.id) !== String(item.id)
    )
    const subscription =
      item.options?.filter?.((option) => option.name === 'Sub Options')?.[0]
        ?.value || 'one-time purchase'
    const gaProducts = products.map((product) => {
      const container = {
        item_name: product.name,
        item_id: product.product_id,
        price: product.list_price,
        item_brand: 'Good Ranchers',
        item_category: 'Boxes',
        item_variant: subscription,
        quantity: product.quantity,
      }
      return container
    })

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        items: [gaProducts],
      },
    })
  }

  const isYearGWP = () => {
    const yearChickenCodes = ['LUCNXDW1K8', 'CANDY']

    return (
      yearChickenCodes.includes(cart?.coupons[0]?.code) ||
      wingCoupons.includes(cart?.coupons[0]?.code.toLowerCase())
    )
  }

  return (
    <div
      className="fixed top-0 bottom-0 right-0 p-3  text-grayscale-coal-900 bg-cream-100 m-0 w-full md:w-[360px]  lg:w-[410px] flex flex-col justify-between z-[90]"
      style={{
        transform: drawerOpen
          ? 'translateX(0)'
          : 'translateX(567px)' && 'translateX(762px)',
        transition: 'transform 0.5s ease',
      }}
    >
      <div className="flex flex-col overflow-hidden z-20">
        <div className="flex flex-row justify-between pt-3 items-center">
          <CartIcon navbar={false} />
          <div className="font-knockout-49 font-[360] text-[28px] leading-[21px] text-grayscale-coal-900 uppercase">
            Your Cart
          </div>
          <button
            className="text-black bg-transparent hover:cursor-pointer border-0 right-0 lg:h-[32px] lg:w-[32px] sm:h-[24px] sm:w-[24px] items-center justify-center"
            onClick={() => globalDispatch(closeDrawer())}
          >
            <img
              draggable={false}
              src="/img/rebrand/cart_close.svg"
              alt="close cart svg"
            />
          </button>
        </div>

        <div className="flex flex-col overflow-y-scroll gap-3 pt-5 justify-start ">
          {!!cart &&
            cart.line_items?.physical_items
              ?.filter((item) =>
                item?.options?.length > 0
                  ? !item?.options?.find(
                      (option) =>
                        option?.name === 'Add-Ons' || option?.name === 'GWP'
                    )
                  : true && item?.product_id !== gwpProductId
              )
              ?.map?.((item, index, array) => (
                <>
                  {item.product_id !== 138 && (
                    <CartItem
                      setIsThereMeatInTheCart={setIsThereMeatInTheCart}
                      cartLoading={loading}
                      key={item.id}
                      item={item}
                      onDelete={handleItemDelete}
                      setLoading={setLoading}
                      numProducts={numProducts}
                    />
                  )}
                  {item.product_id === 254 && <BaconButterLineItem />}
                  {index !== array.length - 0 && (
                    <div className="underline-cartItem"></div>
                  )}
                </>
              ))}

          {addOns &&
            addOns?.map((addOn) => (
              <AddOnsCartItem
                handleAddOnDelete={handleAddOnDelete}
                addOn={addOn}
                quantity={addOn?.quantity}
                desc={'Special Add On Item'}
                showDelete={true}
                title={bundles[addOn?.id]?.name}
                image={bundles[addOn?.id]?.thumbnail}
                totalPrice={addOn.realPrice}
                updatedPrice={bundles[addOn?.id]?.totalPrice}
              />
            ))}
          {!!cart &&
            cart.line_items?.digital_items
              ?.filter?.((item) => item.product_id != 134)
              .map?.((item) => (
                <GiftCertificateCartItem
                  key={item.id}
                  item={item}
                  onDelete={handleGiftCertificateItemDelete}
                />
              ))}
          {!!cart &&
            cart.line_items?.physical_items?.map?.(
              (item) =>
                item.product_id === gwpProductId && (
                  <CartGWPItem
                    quantity={item?.quantity}
                    originalPrice={item?.original_price}
                    updatedPrice={item?.list_price}
                    desc={
                      isYearGWP()
                        ? 'Free Gift For A Year!'
                        : 'Free one time Gift!'
                    }
                    showDelete={false}
                    title={item?.name}
                    image={item?.image_url}
                  />
                )
            )}

          {!!!cart && (
            <div className="flex flex-col w-full gap-[24px]">
              <EmptyPlate />
              <span className="text-center lg:p-[24px] p-[12px] font-ringside-ssm-book text-grayscale-coal-500 font-[400] xl:text-[16px] xl:leading-[24px] lg:text-[16px] lg:leading-[24px] md:text-[16px] md:leading-[24px] text-[13px] leading-[20px] ">
                You should add some stuff to your cart. Because that plate isn’t
                an actual item we offer—it’s a metaphor for your sad, lonely,
                empty cart.
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col justify-end mt-3 z-30">
        <Coupon
          cart={cart}
          refetchCart={getCart}
          updateGWP={updateGWPItemQuantity}
          gwpProductID={gwpProductId}
          setGwpProductID={setGwpProductId}
        />

        <div className="flex flex-col gap-[6px]">
          <div className="flex flex-row justify-between items-end font-ringside-ssm-book text-grayscale-coal-900">
            <span className="font-[400] lg:text-[20px] lg:leading-[20px] sm:text-[20px] sm:leading-[20px]">
              Subtotal:
            </span>
            {loading ? (
              <div className="right-5 flex h-[20px] w-[20px] items-center justify-center animate-spin rounded-full overflow-hidden">
                <img
                  src="/img/rebrand/LogoIcon.svg"
                  className="h-[20px] w-[20px]"
                />
              </div>
            ) : (
              <span className="right-2 font-[700] lg:text-[20px] lg:leading-[20px] sm:text-[20px] sm:leading-[20px] pr-2">
                {`$${formatMoney(finalPrice || '0')}`}
              </span>
            )}
          </div>
          <div className="flex flex-col w-full items-center text-beef-500 font-ringside-ssm-book">
            {totalSavings && parseFloat(totalSavings) > 0 && (
              <span className="font-[400] lg:text-[14px] text-[11px]">
                You're saving{' '}
                <span className="font-[600] lg:text-[16px] text-[13px]">
                  ${parseFloat(totalSavings).toFixed(0)}
                </span>{' '}
                on this order!
              </span>
            )}
          </div>
        </div>
        <Link href="/shop">
          <a
            className="block text-center btn-secondary-tw cursor-pointer mt-[12px] py-[10px]"
            onClick={() => globalDispatch(closeDrawer())}
            style={{ transition: 'all 0.1s ease-in-out' }}
          >
            Continue Shopping
          </a>
        </Link>
        {loading ? (
          <div className="btn-primary-tw mt-[12px] py-[10px] block items-center">
            <div className="flex flex-row justify-center gap-[6px]">
              <span>updating cart...</span>
              <div className="flex h-[25px] w-[25px] items-center justify-center rounded-full overflow-hidden animate-spin">
                <img
                  src="/img/rebrand/LogoIcon.svg"
                  className="h-[25px] w-[25px]"
                />
              </div>
            </div>
          </div>
        ) : !!getAllItems(cart)?.length && isBoxInCart ? (
          <Link href="/addons">
            <a
              className="btn-primary-tw mt-[12px] py-[10px] block text-center"
              onClick={beginCheckout}
            >
              begin Checkout
            </a>
          </Link>
        ) : (
          !!getAllItems(cart)?.length && (
            <Link href="/checkout">
              <a
                className="btn-primary-tw mt-[12px] py-[10px] block text-center"
                onClick={beginCheckout}
              >
                begin Checkout
              </a>
            </Link>
          )
        )}
      </div>
    </div>
  )
}

export default Cart
