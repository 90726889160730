import api from '@services/api'

export enum CouponStatus {
  'VALID' = 1,
  'INVALID' = 2,
  'DOES NOT APPLY' = 3,
  'LOADING' = 4,
  'EMPTY' = 0,
}

export const applyCoupon = async (code: string): Promise<CouponStatus> => {
  try {
    const res = await fetch(`/api/coupons/${code}`)

    if (!res.ok) {
      return CouponStatus['INVALID']
    }

    const checkIfCouponApplicable = await api.addStoredCouponToCart(code)

    if (checkIfCouponApplicable?.data?.data?.status) {
      return CouponStatus['DOES NOT APPLY']
    }

    return CouponStatus['VALID']
  } catch (error) {
    console.error(error)
    return CouponStatus.INVALID
  }
}

export const removeCoupon = async (code: string) => {
  try {
    const res = await api.deleteCoupon(code)

    if (!res.ok) {
      return CouponStatus['EMPTY']
    }

    return CouponStatus['empty']
  } catch (error) {
    console.error(error)
  }
}
