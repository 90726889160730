import formatMoney from '@utils/formatMoney'
import React from 'react'
import { wingCoupons, chickenCoupons } from '../GWPCouponList'

const CouponStatus = ({ code, amount }: { code: string; amount: number }) => {
  return (
    <div className="justify-between font-ringside-ssm-book">
      <div className="uppercase leading-3">Code Claimed</div>
      <div className="text-beef-500 pr-2 flex justify-between">
        <div className="uppercase">{code}</div>
        <div>
          {amount > 0
            ? `-$${formatMoney(amount || 0)}`
            : chickenCoupons.includes(code.toLocaleLowerCase())
            ? 'FREE Chicken Added!'
            : wingCoupons.includes(code.toLocaleLowerCase())
            ? 'FREE Wings Added!'
            : 'FREE Item Added!'}
        </div>
      </div>
    </div>
  )
}

export default CouponStatus
