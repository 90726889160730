import { CartItemType } from 'types/carts'
import { formatMoney } from 'utils'
import { useState, useEffect, useContext, useRef } from 'react'
import { UserActionType } from 'types/user-context'
import CartItemQuantity from './ItemOptions/CartItemQuantity'
import CartItemSubscription from './ItemOptions/CartItemSubscription'
import api from '@services/api'
import { UserContext } from 'contexts/user-context'
import checkIfMerch from '@utils/checkIfCategory'
import MerchCartItemVariant from './ItemOptions/MerchCartItemVariant'
import merchData from '@components/Merch/MerchData'
import MerchSticker from '@components/Merch/MerchSticker'
import { Link } from '@mui/material'
import { slugifyName } from '@utils/products'
import { checkIfMeatBox, checkIfGiftBox } from '@utils/checkIfCategory'
import Popup from 'reactjs-popup'
import PickYourProtein from '@components/PickYourProtein'
import ChickenOptions from './ItemOptions/ChickenOptions'
import _ from 'lodash'

const CartItem = ({
  item,
  onDelete,
  cartLoading,
  setLoading,
  numProducts,
  isRibeyeAddon,
  isBurgerBoxAddon,
  isBaconAddOn,
  setIsThereMeatInTheCart,
}: {
  item: CartItemType
  onDelete: (item: CartItemType) => void
  cartLoading: boolean
  setLoading: Function
  numProducts: number
  isRibeyeAddon?: Boolean
  isBurgerBoxAddon?: Boolean
  isBaconAddOn?: Boolean
  setIsThereMeatInTheCart?: Function
}) => {
  const {
    id,
    product_id,
    name,
    quantity,
    options,
    list_price,
    discount_amount,
    image_url,
  } = item
  const {
    dispatch: userDispatch,
    state: { cart, user, isSubscriber },
  } = useContext(UserContext)
  const [selectedQuantity, setSelectedQuantity] = useState(null)
  const [selectedSubscription, setSelectedSubscription] = useState(null)
  const { dispatch } = useContext(UserContext)
  const [optionsFromLabel, setOptionsFromLabel] = useState(null)

  const [optionsFromLabelValues, setOptionsFromLabelValues] = useState(null)

  const [product, setProduct] = useState(null)

  const [userSubscriptionObject, setUserSubscriptionObject] = useState(null)

  const [flavor, setFlavor] = useState<string>(null)

  useEffect(() => {
    setUserSubscriptionObject(isSubscriber)
  }, [isSubscriber])

  useEffect(() => {
    const fetchData = async () => {
      return await api.getProductByID(product_id)
    }
    const subscriptionOption = item?.options?.find(
      (option) => option?.name?.toLowerCase() === 'sub options'
    )
    setSelectedSubscription(subscriptionOption?.valueId)

    const flavorOption = item?.options?.find(
      (option) =>
        option?.name?.toLowerCase() === 'flavor' || option?.nameId === 222
    )
    if (
      !_.isEmpty(flavorOption) &&
      !_.isNull(flavorOption) &&
      !_.isUndefined(flavorOption)
    ) {
      if (flavorOption.name === 'regular' && flavorOption.valueId === 259) {
        setFlavor(null)
      } else if (
        flavorOption.name === 'large' &&
        flavorOption.valueId === 260
      ) {
        setFlavor('Pro')
      } else {
        setFlavor(flavorOption?.value)
      }
    }
    fetchData().then((result) => {
      const product = result.data
      setProduct(product)
    })
  }, [])

  useEffect(() => {
    setOptionsFromLabelValues(
      product?.modifiers[0]?.option_values?.find(
        (option) => option.label === 'Yes'
      )
    )
  }, [product, userSubscriptionObject])

  useEffect(() => {
    if (
      userSubscriptionObject &&
      checkIfMerch(product) &&
      item.options.find((option) => option.name === 'Subscriber Price')
        ?.valueId != optionsFromLabelValues?.id
    ) {
      updateMerchForSubs()
    }
  }, [optionsFromLabelValues])

  useEffect(() => {
    setSelectedQuantity(quantity)
  }, [quantity])

  const updateQuantity = async (val: number) => {
    try {
      setLoading(true)
      const payload = {
        product_id: product_id,
        options_selections: options,
        quantity: val,
      }
      const cartRes = await api.editCartItem(id, payload)
      const { data = null } = cartRes.data || {}
      dispatch({ type: UserActionType.SET_CART, payload: data })
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const updateSubscriptionOptions = async (selectedOption, val) => {
    try {
      setLoading(true)
      const newItemOptions = {
        product_id: product_id,
        option_selections: selectedOption,
        variant_id: item.variant_id || 0,
        quantity: val,
      }
      const cartRes = await api.editCartItemSubscriptionOptions(
        id,
        newItemOptions
      )
      const { data = null } = cartRes.data || {}
      dispatch({ type: UserActionType.SET_CART, payload: data })
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const updateMerchVariants = async (selectedVariant, val) => {
    try {
      setLoading(true)
      const newItemVariants = {
        product_id: product_id,
        variant_id: parseInt(selectedVariant),
        quantity: val,
      }
      const cartRes = await api.editCartItemSubscriptionOptions(
        id,
        newItemVariants
      )
      const { data = null } = cartRes.data || {}
      dispatch({ type: UserActionType.SET_CART, payload: data })
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }
  const isApparel = merchData[product?.id]?.apparel
  const isMerch = checkIfMerch(product)
  const isGiftBox = checkIfGiftBox(product)
  const isMeatBox = checkIfMeatBox(product)

  const updateMerchForSubs = async () => {
    const subscriptionOptions = [
      {
        option_id: optionsFromLabelValues?.option_id,
        option_value: String(optionsFromLabelValues?.id),
      },
    ]

    updateSubscriptionOptions(subscriptionOptions, selectedQuantity)
  }

  const handleSubscriptionChange = (e) => {
    const selectedValueSize = e.target.value

    setSelectedSubscription(selectedValueSize.option_value)

    const optionID = product.modifiers.find(
      (modifier) => modifier.display_name.toLowerCase() === 'sub options'
    ).id

    const subscriptionOptions = [
      {
        option_id: optionID,
        option_value: Number(selectedValueSize),
      },
    ]

    selectedValueSize
      ? updateSubscriptionOptions(subscriptionOptions, selectedQuantity)
      : null
  }

  const handleMerchSizeChange = (e) => {
    const selectedValue = e.target.value

    // setSelectedSubscription(selectedValue.option_value)

    selectedValue ? updateMerchVariants(selectedValue, selectedQuantity) : null
  }

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value)
    setSelectedQuantity(newQuantity)
    updateQuantity(newQuantity)
  }

  const isAddOn =
    item.product_id === 142
      ? true
      : item.product_id === 127 &&
        !!item.options.find((option) => option.name === 'July 1st Promo')
      ? true
      : item.product_id === 140
      ? true
      : item.product_id === 231
      ? true
      : false

  const isFreeGift =
    item.product_id === 134 ||
    item.product_id === 131 ||
    item.product_id == 245 ||
    item.product_id == 246

  const popupRef = useRef(null)

  function closePopup() {
    popupRef?.current?.close()
  }

  function openPopup() {
    popupRef?.current?.open()
  }

  return (
    <div className="flex flex-row xl:gap-[10px] lg:gap-[10px] md:gap-[10px] sm:gap-[6px] gap-[6px]">
      <div className="flex flex-row w-auto relative">
        <Link
          href={`/${
            isMerch ? 'merch' : isGiftBox ? 'gifts' : isMeatBox ? 'product' : ''
          }/${slugifyName(product?.name)}`}
        >
          <div className="flex flex-row w-auto relative">
            <img
              src={image_url}
              className="object-cover xl:h-[96px] lg:h-[96px] md:h-[86px] h-[74px] xl:w-[96px] lg:w-[96px] md:w-[86px] w-[74px]"
            />
            {isMerch && isSubscriber && (
              <MerchSticker
                stickerColor="#FFBF3F"
                stickerText="10%&#10;off"
                stickerTextColor="night-800"
              />
            )}
          </div>
        </Link>
      </div>
      <div className="flex flex-col w-full justify-end lg:pb-2 xl:gap-[10px] lg:gap-[10px] md:gap-[10px] sm:gap-[6px] gap-[6px] font-ringside-ssm-medium lg:text-[16px] lg:leading-[16px] md:text-[16px] md:leading-[16px] text-[13px] leading-[13px]">
        <div className="flex flex-col w-full justify-end mt-[6px] mb-[6px] xl:gap-[10px] lg:gap-[10px] md:gap-[10px] sm:gap-[6px] gap-[6px] font-ringside-ssm-medium lg:text-[16px] lg:leading-[16px] md:text-[16px] md:leading-[16px] text-[13px] leading-[13px]">
          <div className="flex flex-row justify-between gap-[4px] bottom-0 w-full">
            {isFreeGift ? (
              <span
                onClick={openPopup}
                className="font-[700] capitalize relative top-1 !no-underline text-grayscale-coal-900 hover:cursor-pointer"
              >
                {!_.isNull(flavor) ? name + '-' + flavor : name}
              </span>
            ) : (
              <Link
                href={`/${
                  isMerch
                    ? 'merch'
                    : isGiftBox
                    ? 'gifts'
                    : isMeatBox
                    ? 'product'
                    : ''
                }/${slugifyName(product?.name)}`}
              >
                <a
                  className="!css-1fy114c-MuiTypography-root-MuiLink-root font-[700] capitalize relative top-1 !no-underline !text-grayscale-coal-900 "
                  href={`/${
                    isMerch
                      ? 'merch'
                      : isGiftBox
                      ? 'gifts'
                      : isMeatBox
                      ? 'product'
                      : ''
                  }/${slugifyName(product?.name)}`}
                >
                  {!_.isNull(flavor) ? name + '—' + flavor : name}
                </a>
              </Link>
            )}
            <button
              onClick={() => onDelete(item)}
              className="hover:cursor-pointer border-none "
            >
              <img
                draggable={false}
                src="/img/rebrand/cart_delete.svg"
                alt="delete item svg"
              />
            </button>
          </div>
          <div className="flex flex-row justify-between gap-[4px] w-full items-end">
            <div className="flex flex-row xl:gap-[12px] lg:gap-[12px] md:gap-[10px] gap-[8px] justify-start w-full items-end ">
              <CartItemQuantity
                cartLoading={cartLoading}
                selectedQuantity={selectedQuantity}
                handleQuantityChange={handleQuantityChange}
                limit={null}
                numProducts={numProducts}
                isAddOn={isAddOn}
                isFreeGift={isFreeGift}
              />

              {isApparel ? (
                <MerchCartItemVariant
                  handleMerchSizeChange={handleMerchSizeChange}
                  product={product}
                  variant_id={item?.variant_id}
                />
              ) : (
                !isMerch && (
                  <CartItemSubscription
                    id={product?.id}
                    cartLoading={cartLoading}
                    selectedSubscription={selectedSubscription}
                    handleSubscriptionChange={handleSubscriptionChange}
                    options={
                      product?.modifiers.find(
                        (modifier) =>
                          modifier.display_name.toLowerCase() === 'sub options'
                      )?.option_values
                    }
                    isAddOn={isAddOn}
                  />
                )
              )}
            </div>

            <div className="flex flex-col gap-[2px] items-end justify-end">
              <span
                className={`${
                  item.original_price === list_price ||
                  (checkIfMerch(product) && !isSubscriber)
                    ? 'hidden '
                    : ''
                }text-grayscale-coal-500 font-[400] xl:text-[10px] xl:leading-[10px] lg:text-[10px] lg:leading-[10px] md:text-[10px] md:leading-[10px] sm:text-[8px] sm:leading-[8px] text-[8px] leading-[8px] line-through decoration-beef-400/40 decoration-2`}
              >
                {`$${formatMoney(
                  (selectedQuantity * item.original_price).toFixed(2)
                )}`}
              </span>

              <span className="font-[700] xl:text-[13px] xl:leading-[13px] lg:text-[13px] lg:leading-[13px] md:text-[13px] md:leading-[13px] sm:text-[10px] sm:leading-[10px] text-[10px] leading-[10px]">
                ${formatMoney((quantity * list_price).toFixed(2))}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartItem
