import { useState, useEffect } from 'react'

const CartItemQuantity = ({
  cartLoading,
  selectedQuantity,
  handleQuantityChange,
  numProducts,
  limit,
  isAddOn,
  isFreeGift,
}: {
  cartLoading: boolean
  selectedQuantity: number
  handleQuantityChange: any
  numProducts: number
  limit: number
  isAddOn: boolean
  isFreeGift: boolean
}) => {
  const [quantityOptions, setQuantityOptions] = useState([])

  useEffect(() => {
    const newOptions = limit
      ? Array.from({ length: limit }, (_, i) => i + 1)
      : Array.from({ length: 9 }, (_, i) => i + 1)

    if (selectedQuantity > newOptions.length) {
      handleQuantityChange({ target: { value: newOptions.length } })
    }

    setQuantityOptions(newOptions)
  }, [numProducts, selectedQuantity, handleQuantityChange])

  return (
    <div className="group inline-block lg:w-[24px] md:w-[24px] sm:w-[20px] w-[20px] hover:cursor-pointer">
      <div className="flex flex-col items-center justify-end ">
        <span className="font-[400] lg:text-[10px] lg:leading-[10px] md:text-[10px] md:leading-[10px] sm:text-[8px] sm:leading-[8px] text-[8px] leading-[8px]">
          QTY
        </span>
        <div className="flex flex-row lg:h-[24px] md:h-[24px] sm:h-[20px] h-[20px] lg:w-[24px] md:w-[24px] sm:w-[20px] w-[20px] items-center justify-center text-center">
          <span className="font-[400]  lg:text-[13px] lg:leading-[13px] md:text-[13px] md:leading-[13px] sm:text-[10px] sm:leading-[10px] text-[10px] leading-[10px]">
            <select
              className="quantity-dropdown  text-4xs lg:text-[13px] ml-[10px] md:ml-1 lg:ml-0 lg:h-[24px] lg:mt-2 text-black"
              value={selectedQuantity}
              onChange={handleQuantityChange}
              disabled={cartLoading || isAddOn || isFreeGift}
            >
              {quantityOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </span>
        </div>
      </div>
    </div>
  )
}

export default CartItemQuantity
